import React from 'react';

import {
    Breadcrumbs,
    Link,
    Divider,
    Tab,
    AccordionSummary,
    AccordionDetails,
    Button,
    Snackbar,
    Backdrop,
    Dialog,
    DialogContent,
    CircularProgress,
    Slide,
} from '@material-ui/core';

import {
    TabContext,
    TabList,
    TabPanel
} from '@material-ui/lab';

import {
    AssignmentIndRounded,
    AttachFileRounded,
    ExpandMore,
    WarningRounded
} from '@material-ui/icons';

// import { Link } from 'react-router-dom';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAlert from '@material-ui/lab/Alert';

import { 
    // StylesProvider, 
    withStyles 
} from "@material-ui/core/styles";

import './css/clientProfile.css';

import conservativeLogo from '../../img/conservative.png';
import moderateLogo from '../../img/moderate.png';
import aggressiveLogo from '../../img/aggressive.png';
import downloadLogo from '../../img/downloadLogo.png'

var imgSrc = '';
const DATE_OPTIONS = {year: 'numeric', month: 'long', day: 'numeric'};
const DATE_OPTIONS_TIME = {year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'};

const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "PHP",
    minimumFractionDigits: 2,
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Accordion = withStyles({
    root: {
      border: 'none !important',
    //   border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none !important',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
})(MuiAccordion);

const ShortKYC = (props) => {
    return(
        <div>
            <Accordion style={{width: '100%'}}>
                <AccordionSummary
                    expandIcon={<ExpandMore/>}
                    aria-controls="panel1-content"
                    id="panel1-short"
                >
                    <label className={'accordionTitle'}>Personal Information</label>
                </AccordionSummary>
                <AccordionDetails>
                    <table className={'accordionTable'}>
                        <tbody>
                            <tr>
                                <td>Full Name</td>
                                <td>{(((props.props.name.firstName !== undefined) && 
                                      (props.props.name.middleName !== undefined) && 
                                      (props.props.name.lastName !== undefined)) 
                                      &&
                                      ((props.props.name.firstName !== '') && 
                                      (props.props.name.middleName !== '') && 
                                      (props.props.name.lastName !== '')))
                                        ? `${props.props.name.firstName} ${props.props.name.middleName} ${props.props.name.lastName}`
                                        : 'N/A'
                                }
                                </td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td style={{textTransform: 'none'}}>{props.props.email}
                                </td>
                            </tr>
                            <tr>
                                <td>Mobile number</td>
                                <td>{props.props.mobileNumber}
                                </td>
                            </tr>
                            <tr>
                                <td>Gender</td>
                                <td>{props.props.gender}
                                </td>
                            </tr>
                            <tr>
                                <td>Civil Status</td>
                                <td>{props.props.civil.civilStatus !== undefined
                                        ? props.props.civil.civilStatus
                                        : 'N/A'}
                                </td>
                            </tr>
                            {props.props.civil.civilStatus !== undefined && props.props.civil.civilStatus === 'married' &&
                                (
                                <tr>
                                    <td>Spouse</td>
                                    <td>{props.props.civil.spouseName !== undefined 
                                            ? props.props.civil.spouseName
                                            : 'N/A'
                                        }</td>
                                </tr>
                                )
                            }
                            <tr>
                                <td>Date of birth</td>
                                <td>{props.props.dateOfBirth !== undefined 
                                        ? (new Date(props.props.dateOfBirth).toLocaleDateString('en-US', DATE_OPTIONS))
                                        : 'N/A'
                                    }</td>
                            </tr>
                            <tr>
                                <td>Place of Birth</td>
                                <td>{((props.props.placeOfBirth !== undefined) && (props.props.placeOfBirth !== ''))
                                        ? props.props.placeOfBirth
                                        : 'N/A'}
                                </td>
                            </tr>
                            <tr>
                                <td>Nationality</td>
                                <td>{((props.props.nationality !== undefined) && (props.props.nationality !== ''))
                                        ? props.props.nationality
                                        : 'N/A'}
                                </td>
                            </tr>
                            <tr>
                                <td>SSS/GSIS</td>
                                <td>{((props.props.ids.SSS !== undefined) && (props.props.ids.SSS !== ''))
                                        ? props.props.ids.SSS
                                        : 'N/A'}
                                </td>
                            </tr>
                            <tr>
                                <td>TIN</td>
                                <td>{((props.props.ids.TIN !== undefined) && (props.props.ids.TIN !== ''))
                                        ? props.props.ids.TIN
                                        : 'N/A'}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </AccordionDetails>
            </Accordion>
            <Accordion style={{width: '100%'}}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2-content"
                    id="panel2-short"
                >
                    <label className={'accordionTitle'}>Address Details</label>
                </AccordionSummary>
                <AccordionDetails>
                    <table className={'accordionTable'}>
                        <tbody>
                            <tr>
                                <td colSpan="2" className={'accordionTableTitle'}>Current Address</td>
                            </tr>
                            <tr>
                                <td>Province</td>
                                <td>{((props.props.currentAdd.province !== undefined) && (props.props.currentAdd.province !== ''))
                                        ? props.props.currentAdd.province
                                        : 'N/A'}
                                </td>
                            </tr>
                            <tr>
                                <td>City</td>
                                <td>{((props.props.currentAdd.city !== undefined) && (props.props.currentAdd.city !== ''))
                                        ? props.props.currentAdd.city
                                        : 'N/A'}
                                </td>
                            </tr>
                            <tr>
                                <td>Barangay</td>
                                <td>{((props.props.currentAdd.barangay !== undefined) && (props.props.currentAdd.barangay !== ''))
                                        ? props.props.currentAdd.barangay
                                        : 'N/A'}
                                </td>
                            </tr>
                            <tr>
                                <td>Zip Code</td>
                                <td>{((props.props.currentAdd.zip !== undefined) && (props.props.currentAdd.zip !== ''))
                                        ? props.props.currentAdd.zip
                                        : 'N/A'}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" className={'accordionTableTitle'}>Permanent Address/Mailing Address</td>
                            </tr>
                            <tr>
                                <td>Province</td>
                                <td>{((props.props.permanentAdd.province !== undefined) && (props.props.permanentAdd.province !== ''))
                                        ? props.props.permanentAdd.province
                                        : 'N/A'}
                                </td>
                            </tr>
                            <tr>
                                <td>City</td>
                                <td>{((props.props.permanentAdd.city !== undefined) && (props.props.permanentAdd.city !== ''))
                                        ? props.props.permanentAdd.city
                                        : 'N/A'}
                                </td>
                            </tr>
                            <tr>
                                <td>Barangay</td>
                                <td>{((props.props.permanentAdd.barangay !== undefined) && (props.props.permanentAdd.barangay !== ''))
                                        ? props.props.permanentAdd.barangay
                                        : 'N/A'}
                                </td>
                            </tr>
                            <tr>
                                <td>Zip Code</td>
                                <td>{((props.props.permanentAdd.zip !== undefined) && (props.props.permanentAdd.zip !== ''))
                                        ? props.props.permanentAdd.zip
                                        : 'N/A'}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" className={'accordionTableTitle'}>Business Address</td>
                            </tr>
                            <tr>
                                <td>Province</td>
                                <td>{((props.props.businessAdd.province !== undefined) && (props.props.businessAdd.province !== ''))
                                        ? props.props.businessAdd.province
                                        : 'N/A'}
                                </td>
                            </tr>
                            <tr>
                                <td>City</td>
                                <td>{((props.props.businessAdd.city !== undefined) && (props.props.businessAdd.city !== ''))
                                        ? props.props.businessAdd.city
                                        : 'N/A'}
                                </td>
                            </tr>
                            <tr>
                                <td>Barangay</td>
                                <td>{((props.props.businessAdd.barangay !== undefined) && (props.props.businessAdd.barangay !== ''))
                                        ? props.props.businessAdd.barangay
                                        : 'N/A'}
                                </td>
                            </tr>
                            <tr>
                                <td>Zip Code</td>
                                <td>{((props.props.businessAdd.zip !== undefined) && (props.props.businessAdd.zip !== ''))
                                        ? props.props.businessAdd.zip
                                        : 'N/A'}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </AccordionDetails>
            </Accordion>
            <Accordion style={{width: '100%'}}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2-content"
                    id="panel3-short"
                >
                    <label className={'accordionTitle'}>Profesional Details</label>
                </AccordionSummary>
                <AccordionDetails>
                    <table className={'accordionTable'}>
                        <tbody>
                            <tr>
                                <td>Primary Occupation</td>
                                <td>{((props.props.work.primaryOccupation !== undefined) && (props.props.work.primaryOccupation !== ''))
                                        ? props.props.work.primaryOccupation
                                        : 'N/A'}
                                </td>
                            </tr>
                            <tr>
                                <td>Name of employer</td>
                                <td>{((props.props.work.nameOfEmployer !== undefined) && (props.props.work.nameOfEmployer !== ''))
                                        ? props.props.work.nameOfEmployer
                                        : 'N/A'}
                                </td>
                            </tr>
                            <tr>
                                <td>Nature of business</td>
                                <td>{((props.props.work.businessNature !== undefined) && (props.props.work.businessNature !== ''))
                                        ? props.props.work.businessNature
                                        : 'N/A'}
                                </td>
                            </tr>
                            <tr>
                                <td>Office Tel. No.</td>
                                <td>{((props.props.work.businessNum !== undefined) && (props.props.work.businessNum !== ''))
                                        ? props.props.work.businessNum
                                        : 'N/A'}
                                </td>
                            </tr>
                            <tr>
                                <td>Annual Income</td>
                                <td>{((props.props.work.annualIncomeAmount !== undefined) && (props.props.work.annualIncomeAmount !== ''))
                                        ? formatter.format(props.props.work.annualIncomeAmount)
                                        : 'PHP 0.00'}
                                </td>
                            </tr>
                            <tr>
                                <td>Source of funds</td>
                                <td>{((props.props.work.sourceOfFunds !== undefined) && (props.props.work.sourceOfFunds !== ''))
                                        ? props.props.work.sourceOfFunds
                                        : 'N/A'}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" className={'accordionTableTitle'}>Financial advisory information</td>
                            </tr>
                            <tr>
                                <td>Financial advisor code</td>
                                <td>{((props.props.investmentManager.investmentManagerId !== undefined) && (props.props.investmentManager.investmentManagerId !== ''))
                                        ? props.props.investmentManager.investmentManagerId
                                        : 'N/A'}
                                </td>
                            </tr>
                            <tr>
                                <td>Financial advisor name</td>
                                <td>{((props.props.investmentManager.investmentManagerName !== undefined) && (props.props.investmentManager.investmentManagerName !== ''))
                                        ? props.props.investmentManager.investmentManagerName
                                        : 'N/A'}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </AccordionDetails>
            </Accordion>
            <Accordion style={{width: '100%'}}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2-content"
                    id="panel5-long"
                >
                    <label className={'accordionTitle'}>Settlement Details</label>
                </AccordionSummary>
                <AccordionDetails>
                    <table className={'accordionTable'}>
                        <tbody>
                            <tr>
                                <td>Account Name</td>
                                <td>{props.props.accountDetails
                                        ? (props.props.accountDetails.accountName) && (props.props.accountDetails.accountName !== '')
                                            ? props.props.accountDetails.accountName
                                            : 'N/A'
                                        : 'N/A'}
                                </td>
                            </tr>
                            <tr>
                                <td>Name of Bank</td>
                                <td>{props.props.accountDetails
                                        ? (props.props.accountDetails.bankName) && (props.props.accountDetails.bankName !== '')
                                            ? props.props.accountDetails.bankName
                                            : 'N/A'
                                        : 'N/A'}
                                </td>
                            </tr>
                            <tr>
                                <td>Bank account number</td>
                                <td>{props.props.accountDetails
                                        ? (props.props.accountDetails.bankAccountNumber !== undefined) && (props.props.accountDetails.bankAccountNumber !== '')
                                            ? props.props.accountDetails.bankAccountNumber
                                            : 'N/A'
                                        : 'N/A'}
                                </td>
                            </tr>
                            <tr>
                                <td>Current or Savings Account </td>
                                <td>{props.props.accountDetails
                                        ? props.props.accountDetails.savingsAccount 
                                            ? 'Savings Account' 
                                            : 'Current Account'
                                        : 'N/A'
                                }</td>
                            </tr>
                        </tbody>
                    </table>
                </AccordionDetails>
            </Accordion>
        </div>
    )
};

const LongKYC = (props) => {
    var incomeSelection = '';
    if(props.props.annualIncome.annualIncome1){
        incomeSelection = 'Less than 250,000.00';
    } else if(props.props.annualIncome.annualIncome2){
        incomeSelection = '250,000.00 to 500,000.00';
    } else if(props.props.annualIncome.annualIncome3){
        incomeSelection = '500,000.00 to less than 1,000,000.00';
    } else if(props.props.annualIncome.annualIncome4){
        incomeSelection = 'Greater than 1,000,000.00';
    } else {
        incomeSelection = 'N/A';
    }

    return(
        <div>
            <Accordion style={{width: '100%'}}>
                <AccordionSummary
                    expandIcon={<ExpandMore/>}
                    aria-controls="panel1-content"
                    id="panel1-long"
                >
                    <label className={'accordionTitle'}>Financial Profile</label>
                </AccordionSummary>
                <AccordionDetails>
                    <table className={'accordionTable'}>
                        <tbody>
                            <tr>
                                <td>Income Selection</td>
                                <td>{incomeSelection}</td>
                            </tr>
                            <tr>
                                <td>
                                    Are there any other funds/financial product you would like to invest in the future?
                                </td>
                                <td>{props.props.otherProducts}
                                </td>
                            </tr>
                            <tr>
                                <td>Do you anticipate buying more share on a regular basis?</td>
                                <td>{props.props.regularBasis}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </AccordionDetails>
            </Accordion>
            <Accordion style={{width: '100%'}}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2-content"
                    id="panel2-long"
                >
                    <label className={'accordionTitle'}>PEP Declaration</label>
                </AccordionSummary>
                <AccordionDetails>
                    <table className={'accordionTable'}>
                        <tbody>
                            <tr>
                                <td>Have you work in a government agency or insitution?</td>
                                <td>{props.props.PEP.governmentWork ? 'Yes' : 'No'}</td>
                            </tr>
                            <tr>
                                <td>
                                    Do you have any relative who is / was an elected governemnt offical or 
                                    who is/was an appointed government offical?
                                </td>
                                <td>{props.props.PEP.governmentRelative ? 'Yes' : 'No'}</td>
                            </tr>
                        </tbody>
                    </table>
                </AccordionDetails>
            </Accordion>
            <Accordion style={{width: '100%'}}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2-content"
                    id="panel3-long"
                >
                    <label className={'accordionTitle'}>Risk Profile</label>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={'riskContainer'}>
                        {props.props.clientDetails.riskProfile !== undefined
                            ? (<>             
                                    {props.props.clientDetails.riskProfile === 'conservative' 
                                        && <>
                                            <div className={'riskIconBackgroundCon'}>
                                                <img 
                                                className={'iconSize'} 
                                                src={conservativeLogo} alt="conservative" />
                                            </div>
                                            <div>
                                                <label className={'itemTitle'}>Conservative</label><br/>
                                                <label className={'itemDescription'}>You are an investor with a low appetite for risk and you’re amenable to modest returns.</label>
                                            </div> 
                                           </>
                                    }

                                    {props.props.clientDetails.riskProfile === 'moderate' 
                                        && <>
                                            <div className={'riskIconBackgroundMod'}>
                                                <img className={'iconSize'} src={moderateLogo} alt="moderate" /> 
                                            </div>
                                            <div>
                                                <label className={'itemTitle'}>Moderate</label><br/>
                                                <label className={'itemDescription'}>You are ready to invest and are currently looking at a long-term investing horizon.</label>
                                            </div>
                                           </>
                                        
                                    }

                                    {props.props.clientDetails.riskProfile === 'aggressive' 
                                        && <>
                                            <div className={'riskIconBackgroundAgg'}>
                                                <img className={'iconSize'} src={aggressiveLogo} alt="aggressive" />
                                            </div>
                                            <div>
                                                <label className={'itemTitle'}>Aggresive</label><br/>
                                                <label className={'itemDescription'}>You have a high appetite for risk and willing to invest for the long-term.</label>
                                            </div> 
                                           </>
                                    
                                    }
                               </>)
                            : <label className={'itemTitle'}>N/A</label>    
                        }
                        
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion style={{width: '100%'}}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2-content"
                    id="panel4-long"
                >
                    <label className={'accordionTitle'}>FATCA</label>
                </AccordionSummary>
                <AccordionDetails>
                    <table className={'accordionTable'}>
                        <tbody>
                            <tr>
                                <td>Are you a US citizen?</td>
                                <td>{props.props.FATCA.usCitizen ? 'Yes' : 'No'}</td>
                            </tr>
                            <tr>
                                <td>US resident?</td>
                                <td>{props.props.FATCA.usResident ? 'Yes' : 'No'}</td>
                            </tr>
                            <tr>
                                <td>US resident alien?</td>
                                <td>{props.props.FATCA.usAlien ? 'Yes' : 'No'}</td>
                            </tr>
                            <tr>
                                <td>Do you have a current US telephone number?</td>
                                <td>{props.props.FATCA.usPhone ? 'Yes' : 'No'}</td>
                            </tr>
                            <tr>
                                <td>Where you born in US?</td>
                                <td>{props.props.FATCA.usBorn ? 'Yes' : 'No'}</td>
                            </tr>
                            <tr>
                                <td>Do you have a current US residence address?</td>
                                <td>{props.props.FATCA.usAddressResidence ? 'Yes' : 'No'}</td>
                            </tr>
                            <tr>
                                <td>Do you have a current US mailing address? (including US P.O)</td>
                                <td>{props.props.FATCA.usAddressMailing ? 'Yes' : 'No'}</td>
                            </tr>
                            <tr>
                                <td>Have you  issued a standing instruction to transferfunds to an account</td>
                                <td>{props.props.FATCA.usAccount ? 'Yes' : 'No'}</td>
                            </tr>
                            <tr>
                                <td>
                                    Have you issued a power of Attorney or granted signatory authority 
                                    to a person with US address?
                                </td>
                                <td>{props.props.FATCA.usPOA ? 'Yes' : 'No'}</td>
                            </tr>
                            <tr>
                                <td>
                                    Do you have “in care of” address or “hold mail” address
                                    that is the sole address for an account 
                                    (whether such address in the US or outside the US) ?
                                </td>
                                <td>{props.props.FATCA.usCareOfAddress ? 'Yes' : 'No'}</td>
                            </tr>
                        </tbody>
                    </table>
                </AccordionDetails>
            </Accordion>
        </div>
    )
};

const ClientAttachments = (props) => {
    return(
        <>  
            <label className={'attachmentLabel'}>Selfie With Valid ID</label>
            {props.props.state.selfie &&
                <div 
                    data-type={3}
                    className={'attachmentDiv'}
                    onClick={props.props.onShowImage()}
                >
                    <img src={downloadLogo} className={'attachmentIcon'} alt="download" />
                    <div style={{textAlign: 'left'}}>
                        <label className={'itemTitle'}>Selfie</label><br/>
                        <label className={'itemDescription'}>{`${props.props.state.selfieSize} MB`}</label>
                    </div>
                </div>
            }
            <hr class="rounded"></hr>

            <label className={'attachmentLabel'}>Valid IDs</label>
            {props.props.state.idPhotoFront &&
                <div 
                    data-type={1}
                    className={'attachmentDiv'}
                    onClick={props.props.onShowImage()}
                >
                    <img src={downloadLogo} className={'attachmentIcon'} alt="download" />
                    <div style={{textAlign: 'left'}}>
                        <label className={'itemTitle'}>Front ID</label><br/>
                        <label className={'itemDescription'}>{`${props.props.state.idPhotoFrontSize} MB`}</label>
                    </div>
                </div>
            }
            <div></div> 
            {props.props.state.idPhotoBack &&
                <div 
                    data-type={2}
                    className={'attachmentDiv'}
                    onClick={props.props.onShowImage()}
                >
                    <img src={downloadLogo} className={'attachmentIcon'} alt="download" />
                    <div style={{textAlign: 'left'}}>
                        <label className={'itemTitle'}>Back ID</label><br/>
                        <label className={'itemDescription'}>{`${props.props.state.idPhotoBackSize} MB`}</label>
                    </div>
                </div>
            }
            {props.props.state.proofOfAddress &&
                <div 
                    data-type={4}
                    className={'attachmentDiv'}
                    onClick={props.props.onShowImage()}
                >
                    <img src={downloadLogo} className={'attachmentIcon'} alt="download" />
                    <div style={{textAlign: 'left'}}>
                        <label className={'itemTitle'}>Proof Of Address</label><br/>
                        <label className={'itemDescription'}>{`${props.props.state.proofOfAddressSize} MB`}</label>
                    </div>
                </div>
            }     
            {props.props.state.PEPDocument &&
                <div 
                    data-type={5}
                    className={'attachmentDiv'}
                    onClick={props.props.onShowImage()}
                >
                    <img src={downloadLogo} className={'attachmentIcon'} alt="download" />
                    <div style={{textAlign: 'left'}}>
                        <label className={'itemTitle'}>PEP Document</label><br/>
                        <label className={'itemDescription'}>{`${props.props.state.PEPDocumentSize} MB`}</label>
                    </div>
                </div>
            }
        </>
    )
}

const PendingTransactions = (props) => {
    var pendingTransaction = props.props.state.investmentDetails.filter(item => item.status === 'pending');

    return(
        <>
            <div className={pendingTransaction.length > 4 ? 'pendingApprovedContainer' : ''}>
                {pendingTransaction.length > 0 ?
                    pendingTransaction.map((item, index) => (
                        <>
                            <div 
                                key={item.transId}
                                data-trans-key={item.transId}
                                data-trans-type={'pending'}
                                onClick={props.props.onItemTransactionChange()}
                                className={props.props.state.transSelectedId === item.transId ? 'tabItemsContainerActive' : 'tabItemsContainer'}
                            >
                                <div key={item.fundDetails.fundName}>
                                    <label 
                                        className={'itemTitle'}
                                    >
                                        {item.fundDetails.fundName !== undefined 
                                            ? item.fundDetails.fundName
                                            : 'N/A' }
                                    </label><br/>
                                    <label 
                                        className={'itemDescription'}
                                    > Transaction ID: &nbsp;
                                        {item.transId !== undefined 
                                            ? item.transId
                                            : 'N/A' }
                                    </label><br/>
                                    <label 
                                        className={'itemDescription'}
                                    > Date and Time: &nbsp;
                                        {item.datePlaced !== undefined 
                                            ? (new Date(item.datePlaced.toDate()).toLocaleDateString('en-US', DATE_OPTIONS_TIME))
                                            : 'N/A' }
                                    </label><br/>
                                    <label 
                                        className={'itemDescription'}
                                    > Transaction Amount: &nbsp;
                                        {item.amount !== undefined 
                                            ? formatter.format(item.amount)
                                            : 'N/A' }
                                    </label>
                                </div>
                            </div>
                            <br/>
                        </>

                    )) :
                <label className={'itemDescription'}>No pending investments.</label>
                }
            </div>
        </>
    )
}

const ProcessingTransactions = (props) => {
    var processingTransaction = props.props.state.investmentDetails.filter(item => item.status === 'processing');

    return(
        <>
            <div className={processingTransaction.length > 4 ? 'pendingApprovedContainer' : ''}>
                {processingTransaction.length > 0 ?
                    processingTransaction.map((item, index) => (
                        <>
                            <div 
                                key={item.transId}
                                data-trans-key={item.transId}
                                data-trans-type={'processing'}
                                onClick={props.props.onItemTransactionChange()}
                                className={props.props.state.transSelectedId === item.transId ? 'tabItemsContainerActive' : 'tabItemsContainer'}
                            >
                                <div key={item.fundDetails.fundName}>
                                    <label 
                                        className={'itemTitle'}
                                    >
                                        {item.fundDetails.fundName !== undefined 
                                            ? item.fundDetails.fundName
                                            : 'N/A' }
                                    </label><br/>
                                    <label 
                                        className={'itemDescription'}
                                    > Transaction ID: &nbsp;
                                        {item.transId !== undefined 
                                            ? item.transId
                                            : 'N/A' }
                                    </label><br/>
                                    <label 
                                        className={'itemDescription'}
                                    > Date and Time: &nbsp;
                                        {item.datePlaced !== undefined 
                                            ? (new Date(item.datePlaced.toDate()).toLocaleDateString('en-US', DATE_OPTIONS_TIME))
                                            : 'N/A' }
                                    </label><br/>
                                    <label 
                                        className={'itemDescription'}
                                    > Transaction Amount: &nbsp;
                                        {item.amount !== undefined 
                                            ? formatter.format(item.amount)
                                            : 'N/A' }
                                    </label>
                                </div>
                            </div>
                            <br/>
                        </>
                    )) :
                <label className={'itemDescription'}>No processing investments.</label>    
                }
            </div>
        </>
    )
}

const ApproveTransactions = (props) => {
    var approvedTransaction = props.props.state.investmentDetails.filter(item => item.status === 'approved');

    return(
        <>
            <div className={approvedTransaction.length > 4 ? 'pendingApprovedContainer' : ''}>
                {approvedTransaction.length > 0 ?
                    approvedTransaction.map((item, index) => (
                        <>
                            <div 
                                key={item.transId}
                                data-trans-key={item.transId}
                                data-trans-type={'approved'}
                                onClick={props.props.onItemTransactionChange()}
                                className={props.props.state.transSelectedId === item.transId ? 'tabItemsContainerActive' : 'tabItemsContainer'}
                            >
                                <div key={item.fundDetails.fundName}>
                                    <label 
                                        className={'itemTitle'}
                                    >
                                        {item.fundDetails.fundName !== undefined 
                                            ? item.fundDetails.fundName
                                            : 'N/A' }
                                    </label><br/>
                                    <label 
                                        className={'itemDescription'}
                                    > Transaction ID: &nbsp;
                                        {item.transId !== undefined 
                                            ? item.transId
                                            : 'N/A' }
                                    </label><br/>
                                    <label 
                                        className={'itemDescription'}
                                    > Date and Time: &nbsp;
                                        {item.datePlaced !== undefined 
                                            ? (new Date(item.datePlaced.toDate()).toLocaleDateString('en-US', DATE_OPTIONS_TIME))
                                            : 'N/A' }
                                    </label><br/>
                                    <label 
                                        className={'itemDescription'}
                                    > Transaction Amount: &nbsp;
                                        {item.amount !== undefined 
                                            ? formatter.format(item.amount)
                                            : 'N/A' }
                                    </label>
                                </div>
                            </div>
                            <br/>
                        </>
                    )) :
                <label className={'itemDescription'}>No approved investments.</label>    
                }
            </div>
        </>
    )
}

const MaturedTransactions = (props) => {
    var maturedTransaction = props.props.state.investmentDetails.filter(item => item.status === 'matured');

    return(
        <>
            <div className={maturedTransaction.length > 4 ? 'pendingApprovedContainer' : ''}>
                {maturedTransaction.length > 0 ?
                    maturedTransaction.map((item, index) => (
                        <>
                            <div 
                                key={item.transId}
                                data-trans-key={item.transId}
                                data-trans-type={'matured'}
                                onClick={props.props.onItemTransactionChange()}
                                className={props.props.state.transSelectedId === item.transId ? 'tabItemsContainerActive' : 'tabItemsContainer'}
                            >
                                <div key={item.fundDetails.fundName}>
                                    <label 
                                        className={'itemTitle'}
                                    >
                                        {item.fundDetails.fundName !== undefined 
                                            ? item.fundDetails.fundName
                                            : 'N/A' }
                                    </label><br/>
                                    <label 
                                        className={'itemDescription'}
                                    > Transaction ID: &nbsp;
                                        {item.transId !== undefined 
                                            ? item.transId
                                            : 'N/A' }
                                    </label><br/>
                                    <label 
                                        className={'itemDescription'}
                                    > Date and Time: &nbsp;
                                        {item.datePlaced !== undefined 
                                            ? (new Date(item.datePlaced.toDate()).toLocaleDateString('en-US', DATE_OPTIONS_TIME))
                                            : 'N/A' }
                                    </label><br/>
                                    <label 
                                        className={'itemDescription'}
                                    > Transaction Amount: &nbsp;
                                        {item.amount !== undefined 
                                            ? formatter.format(item.amount)
                                            : 'N/A' }
                                    </label>
                                </div>
                            </div>
                            <br/>
                        </>
                    )) :
                <label className={'itemDescription'}>No matured investments.</label>    
                }
            </div>
        </>
    )
}

const PendingWithdrawals = (props) => {
    var pendingWithdrawals = props.props.state.investmentDetails.filter(item => item.status === 'pendingwithdrawal');

    return(
        <>
            <div className={pendingWithdrawals.length > 4 ? 'pendingApprovedContainer' : ''}>
                {pendingWithdrawals.length > 0 ?
                    pendingWithdrawals.map((item, index) => (
                        <>
                            <div 
                                key={item.transId}
                                data-trans-key={item.transId}
                                data-trans-type={'pendingwithdrawal'}
                                onClick={props.props.onItemTransactionChange()}
                                className={props.props.state.transSelectedId === item.transId ? 'tabItemsContainerActive' : 'tabItemsContainer'}
                            >
                                <div key={item.fundDetails.fundName}>
                                    <label 
                                        className={'itemTitle'}
                                    >
                                        {item.fundDetails.fundName !== undefined 
                                            ? item.fundDetails.fundName
                                            : 'N/A' }
                                    </label><br/>
                                    <label 
                                        className={'itemDescription'}
                                    > Transaction ID: &nbsp;
                                        {item.transId !== undefined 
                                            ? item.transId
                                            : 'N/A' }
                                    </label><br/>
                                    <label 
                                        className={'itemDescription'}
                                    > Date and Time: &nbsp;
                                        {item.datePlaced !== undefined 
                                            ? (new Date(item.datePlaced.toDate()).toLocaleDateString('en-US', DATE_OPTIONS_TIME))
                                            : 'N/A' }
                                    </label><br/>
                                    <label 
                                        className={'itemDescription'}
                                    > Transaction Amount: &nbsp;
                                        {item.amount !== undefined 
                                            ? formatter.format(item.amount)
                                            : 'N/A' }
                                    </label>
                                </div>
                            </div>
                            <br/>
                        </>
                    )) :
                <label className={'itemDescription'}>No pending withdrawals.</label>    
                }
            </div>
									   
							
        </>
    )
}

const ProcessingWithdrawals = (props) => {
    var processingWithdrawals = props.props.state.investmentDetails.filter(item => item.status === 'processingwithdrawal');

    return(
        <>
            <div className={processingWithdrawals.length > 4 ? 'pendingApprovedContainer' : ''}>
                {processingWithdrawals.length > 0 ?
                    processingWithdrawals.map((item, index) => (
                        <>
                            <div 
                                key={item.transId}
                                data-trans-key={item.transId}
                                data-trans-type={'processingwithdrawal'}
                                onClick={props.props.onItemTransactionChange()}
                                className={props.props.state.transSelectedId === item.transId ? 'tabItemsContainerActive' : 'tabItemsContainer'}
                            >
                                <div key={item.fundDetails.fundName}>
                                    <label 
                                        className={'itemTitle'}
                                    >
                                        {item.fundDetails.fundName !== undefined 
                                            ? item.fundDetails.fundName
                                            : 'N/A' }
                                    </label><br/>
                                    <label 
                                        className={'itemDescription'}
                                    > Transaction ID: &nbsp;
                                        {item.transId !== undefined 
                                            ? item.transId
                                            : 'N/A' }
                                    </label><br/>
                                    <label 
                                        className={'itemDescription'}
                                    > Date and Time: &nbsp;
                                        {item.datePlaced !== undefined 
                                            ? (new Date(item.datePlaced.toDate()).toLocaleDateString('en-US', DATE_OPTIONS_TIME))
                                            : 'N/A' }
                                    </label><br/>
                                    <label 
                                        className={'itemDescription'}
                                    > Transaction Amount: &nbsp;
                                        {item.amount !== undefined 
                                            ? formatter.format(item.amount)
                                            : 'N/A' }
                                    </label>
                                </div>
                            </div>
                            <br/>
                        </>
                    )) :
                <label className={'itemDescription'}>No processing withdrawals.</label>    
                }
            </div>
        </>
    )
}

const Withdrawns = (props) => {
    var withdrawns = props.props.state.investmentDetails.filter(item => item.status === 'withdrawn');

    return(
        <>
            <div className={withdrawns.length > 4 ? 'pendingApprovedContainer' : ''}>
                {withdrawns.length > 0 ?
                   withdrawns.map((item, index) => (
                        <>
                            <div 
                                key={item.transId}
                                data-trans-key={item.transId}
                                data-trans-type={'withdrawn'}
                                onClick={props.props.onItemTransactionChange()}
                                className={props.props.state.transSelectedId === item.transId ? 'tabItemsContainerActive' : 'tabItemsContainer'}
                            >
                                <div key={item.fundDetails.fundName}>
                                    <label 
                                        className={'itemTitle'}
                                    >
                                        {item.fundDetails.fundName !== undefined 
                                            ? item.fundDetails.fundName
                                            : 'N/A' }
                                    </label><br/>
                                    <label 
                                        className={'itemDescription'}
                                    > Transaction ID: &nbsp;
                                        {item.transId !== undefined 
                                            ? item.transId
                                            : 'N/A' }
                                    </label><br/>
                                    <label 
                                        className={'itemDescription'}
                                    > Date and Time: &nbsp;
                                        {item.datePlaced !== undefined 
                                            ? (new Date(item.datePlaced.toDate()).toLocaleDateString('en-US', DATE_OPTIONS_TIME))
                                            : 'N/A' }
                                    </label><br/>
                                    <label 
                                        className={'itemDescription'}
                                    > Transaction Amount: &nbsp;
                                        {item.amount !== undefined 
                                            ? formatter.format(item.amount)
                                            : 'N/A' }
                                    </label>
                                </div>
                            </div>
                            <br/>
                        </>
                    )) :
                <label className={'itemDescription'}>No withdrawn transactions.</label>    
                }
            </div>
        </>
    )
}

const ClientTransactions = (props) => {
    var transId = props.props.state.transSelectedId;
    var statusArray = ['pending', 'processing', 'approved', 'matured'];

    var details = props.props.state.investmentDetails.find(item => item.transId === transId);
    
    return(
        details ? (
            <>  
                <div className={'content2Title'}>
                    <div>
                        <label className={'content2ItemTitle'}>
                            {details.fundDetails.fundName
                                ? details.fundDetails.fundName
                                : 'N/A' }
                        </label>
                        <br/>
                        <label className={'content2ItemDescription'}>
                            {`Transaction ID: ${details.transId}`}
                        </label>
                    </div>
                </div>

                <table className={'accordionTable'}>
                    <tbody>
                        <tr>
                            <td>Fund Name</td>
                            <td>{details.fundDetails.fundName
                                    ? details.fundDetails.fundName
                                    : 'N/A' }
                            </td>
                        </tr>
                        <tr>
                            <td>Account Name</td>
                            <td>{details.clientDetails
                                    ? `${details.clientDetails.userFirstName} ${details.clientDetails.userLastName}`
                                    : "N/A"}
                            </td>
                        </tr>
                        <tr>
                            <td>Account ID</td>
                            <td style={{ textTransform: "none" }}>
                                {details.accountNumber}
                            </td>
                        </tr>
                        <tr>
                            <td>Inputed Amount</td>
                            <td>{details.amount !== 'N/A'
                                    ? formatter.format(details.amount) 
                                    : 'N/A' }
                            </td>
                        </tr>
                        <tr>
                            <td>Date and Time</td>
                            <td>{details.datePlaced !== 'N/A'
                                    ? (new Date(details.datePlaced.toDate()).toLocaleDateString('en-US', DATE_OPTIONS_TIME)) 
                                    : 'N/A' }
                            </td>
                        </tr>
                        <tr>
                            <td>Email</td>
                            <td style={{textTransform: 'none'}}>{details.clientDetails.userEmail !== undefined && details.clientDetails.userEmail !== ''
                                    ? details.clientDetails.userEmail
                                    : 'N/A' }
                            </td>
                        </tr>
                        <tr>
                            <td>Contact Number</td>
                            <td>{props.props.state.mobileNumber}
                            </td>
                        </tr>
                        <tr>
                            <td>Company</td>
                            <td>
                                {details.fundCompDetails.fundCompanyName
                                    ? details.fundCompDetails.fundCompanyName
                                    : 'N/A'}
                            </td>
                        </tr>
                        <tr>
                            <td>Folio Number</td>
                            <td>
                                {details.folioNumber}
                            </td>
                        </tr>
                        <tr>
                            <td>Shares</td>
                            <td>
                                {details.shares}
                            </td>
                        </tr>
                        <tr>
                            <td>Rate at Booking Time</td>
                            <td>
                                {details.navps}
                            </td>
                        </tr>
                        <tr>
                            <td>Fee</td>
                            <td>
                                {details.fee}
                            </td>
                        </tr>
                        <tr>
                            <td>Total Value</td>
                            <td>
                                {details.total_value}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <br/>
                <br/>

                {(statusArray.includes(details.status) && details.depositSlip) &&
                    <>
                        <div>
                        <label className={'attachmentLabel'}>Attachments</label>
                        </div>

                        <div 
                            data-type={-1}
                            className={'attachmentDiv'} 
                            onClick={props.props.onShowImage()}
                        >
                            <img src={downloadLogo} className={'attachmentIcon'} alt="download" />
                            <div style={{textAlign: 'left'}}>
                                <label className={'itemTitle'}>{details.depositSlip}</label><br/>
                                <label className={'itemDescription'}>{`${details.depositSlipSize} MB`}</label>
                            </div>
                        </div>
                    </>
                }
                <br/>
                <br/>

                {((details.status === 'pending') || (details.status === 'pendingwithdrawal')) &&
                    <>
                        <Divider/>
                        <Button 
                            className={'btnDecline'} 
                            data-type="decline"
                            onClick={props.props.onhandleDialogType()}
                        >
                            Decline
                        </Button>
                        <Button 
                            className={'btnApprove'} 
                            data-type="approve"
                            onClick={props.props.onhandleDialogType()}
                        >
                            Approve
                        </Button>
                    </>
                }
            </>
        )
        :
        <center>
            <label className={'noInvestmentContent'}>No transactions.</label>
        </center>
    )
}

const ClientProfileForm = (props) => {
    const {
        tabValue,
        itemSelected,
        kycPage,
        email,
        investmentManager,
        name,
        toggleAlert,
        alertType,
        alertMessage,
        clientDetails,
        toggleShowImage,
        imageType,
        idPhotoFrontSrc,
        idPhotoBackSrc,
        proofOfAddressSrc,
        PEPDocumentSrc,
        selfieSrc,
		totalInvestmentAmount,
        isLoading,
        toggleDialog,
        isApproveDecline,
        depositSlipSrc,
        validated,
    } = props.state;

    switch(imageType)
    {
        case -1:
            imgSrc = depositSlipSrc;
            break;

        case 1:
            imgSrc = idPhotoFrontSrc;
            break;

        case 2:
            imgSrc = idPhotoBackSrc;
            break;

        case 3:
            imgSrc = selfieSrc;
            break;

        case 4:
            imgSrc = proofOfAddressSrc;
            break;

        case 5:
            imgSrc = PEPDocumentSrc;
            break;

        default:
            imgSrc = "";
            break;
    }    

    return(
        // <StylesProvider injectFirst>
            <div className={'root'}>
                <div className={'containerLeft'}>
                    <Breadcrumbs className={'breadcrumbsSize'}>
                        <Link color="inherit" href="/">Clients</Link>
                        <Link 
                            color="textPrimary"
                            style={{cursor: 'default', textDecoration: 'none'}}
                        >
                            {((name.firstName !== undefined) && 
                                 (name.middleName !== undefined) && 
                                 (name.lastName !== undefined)) 
                                 &&
                                 ((name.firstName !== '') && 
                                 (name.middleName !== '') && 
                                 (name.lastName !== ''))
                                    ? `${name.firstName} ${name.middleName} ${name.lastName}`
                                    : 'N/A'}
                        </Link>
                    </Breadcrumbs>
                    <br/>
                    <label className={'clientNameLabel'}>
                        {((name.firstName !== undefined) && 
                            (name.middleName !== undefined) && 
                            (name.lastName !== undefined)) 
                            &&
                            ((name.firstName !== '') && 
                            (name.middleName !== '') && 
                            (name.lastName !== ''))
                                ? `${name.firstName} ${name.middleName} ${name.lastName}`
                                : 'N/A'}
                    </label>
                    <br/>
                    <label className={'clientEmailLabel'}>
                        {((email !== undefined) && (email !== ''))
                                ? email
                                : 'N/A' }
                    </label>

                    <table className={'detailsTable'}>
                        <tbody>
                            <tr>
                                <td>Account ID:</td>
                                <td>{clientDetails.accountNumber ? clientDetails.accountNumber : "N/A"}</td>
                            </tr>
                            <tr>
                                <td>Risk Profile:</td>
                                <td style={{textTransform: 'capitalize'}}>{clientDetails.riskProfile ? clientDetails.riskProfile : 'N/A'}</td>
                            </tr>
                            <tr>
                                <td>IM/RM:</td>
                                <td>{((investmentManager.investmentManagerName) && investmentManager.investmentManagerName !== '')
                                        ? investmentManager.investmentManagerName
                                        : 'N/A' }
                                </td>
                            </tr>
                            <tr>
                                <td>Application Status:</td>
                                <td>{validated.toString().toLowerCase() === 'verified' ? 'Approved' : 'To be review'}</td>
                            </tr>
                            <tr>
                                <td>Total Amount Investment</td>
                                <td>{totalInvestmentAmount > 1
                                        ? formatter.format(totalInvestmentAmount)
                                        : 'PHP 0.00'
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <Divider/>

                    <TabContext value={tabValue}>
                        <TabList 
                            onChange={props.onTabChange()}
                            classes={{
                                indicator: 'tabBottomColor',
                            }}
                            >
                                <Tab 
                                    label="Applications" 
                                    value={1} 
                                    className={tabValue === 1 ? 'tabTextActive' : 'tabText'} 
                                />
                                <Tab 
                                    label="Transactions" 
                                    value={2}
                                    className={tabValue === 2 ? 'tabTextActive' : 'tabText'} 
                                />
                        </TabList>
                        <TabPanel 
                            value={1}
                            style={{padding: 0, }}
                        >
                            <br/>
                            <div 
                                onClick={props.onItemChange1()}
                                className={itemSelected === 1 ? 'tabItemsContainerActive' : 'tabItemsContainer'}
                            >
                                <div className={'iconBackground'}>
                                    <AssignmentIndRounded className={'iconSize'} />
                                </div>
                                <div>
                                    <label className={'itemTitle'}>Know your customer Application (KYC)</label><br/>
                                    <label className={'itemDescription'}>{clientDetails.isInvestor ? 'Approved' : 'To be review'}</label>
                                </div>
                            </div>
                            <br/>
                            <div 
                                onClick={props.onItemChange2()}
                                className={itemSelected === 2 ? 'tabItemsContainerActive' : 'tabItemsContainer'}
                            >
                                <div className={'iconBackground'}>
                                    <AttachFileRounded className={'iconSize'} />
                                </div>
                                <div>
                                    <label className={'itemTitle'}>Attachments</label><br/>
                                    <label className={'itemDescription'}>Attached files</label>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel 
                            value={2}
                            style={{padding: 0, }}
                        >
                            <br/>
                           {[{label: 'Pending Investment', content: <PendingTransactions props={props} />},
                              {label: 'Processing Investment', content: <ProcessingTransactions props={props} />},
                              {label: 'On going Investment', content: <ApproveTransactions props={props} />},
                              {label: 'Matured Investment', content: <MaturedTransactions props={props} />},
                              {label: 'Pending Withdrawals', content: <PendingWithdrawals props={props} />},
                              {label: 'Processing Withdrawals', content: <ProcessingWithdrawals props={props} />},
                              {label: 'Withdrawns', content: <Withdrawns props={props} />}
                             ].map((item) => (
                                <Accordion style={{width: '100%'}}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore/>}
                                        aria-controls="panel1-content"
                                        id="panel1-short"
                                    >
                                        <label 
                                            className={'accordionTableTitle'} 
                                            style={{cursor: 'pointer'}}
                                        >
                                            {item.label}
                                        </label>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {item.content}
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </TabPanel>
                    </TabContext>
                </div>
                <div className={'containerRight'}>
                    {tabValue === 1 ? 
                        itemSelected === 1 ? 
                            <>
                                <div className={'content2Title'}>
                                    <div className={'content2IconBackground'}>
                                        <AssignmentIndRounded className={'content2IconSize'} />
                                    </div>
                                    <div>
                                        <label className={'content2ItemTitle'}>Know your customer Application (KYC)</label><br/>
                                        <label className={'content2ItemDescription'}>KYC applicaiton provided by the investor/client</label>
                                    </div>
                                </div>

                                {(clientDetails.isInvestor !== undefined && clientDetails.isInvestor) && <div className="validatedLabel">Application Validated</div>}

                                {kycPage === 1 
                                    ? <ShortKYC props={props.state} />
                                    : <LongKYC props={props.state} />
                                }

                                {clientDetails.hasLongKYC &&
                                    <Button 
                                        disabled={clientDetails.hasLongKYC === undefined}
                                        className={'btnNextPrev'} 
                                        onClick={props.onNextPage()}>
                                            {kycPage === 1 ? 'Show Long KYC' : 'Previous Page'}
                                    </Button> 
                                }
                            
                            </> :
                            <>
                                <div className={'content2Title'}>
                                    <div className={'content2IconBackground'}>
                                        <AttachFileRounded className={'content2IconSize'} />
                                    </div>
                                    <div>
                                        <label className={'content2ItemTitle'}>Attachements</label><br/>
                                    </div>
                                </div>

                                <ClientAttachments props={props} />
                            </>
                        :
                        <ClientTransactions props={props} />
                    }
                </div>

                <Dialog
                    open={toggleDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={!isLoading ? props.onHandleCloseDialog() : ''}
                >
                    <div className={isApproveDecline === 'approve' ? 'dialogIconBackground' : 'dialogIconBackgroundDecline'}>
                        <WarningRounded className={'dialogIcon'}/>
                    </div>
                    <DialogContent>
                        {isApproveDecline === 'approve' ?
                            <>
                                <label className={"dialogTitle"}>Approve Transaction</label><br/>
                                <label className={"dialogDescription"}>
                                    Do you want to approve the client transaction?
                                </label>
                            </>
                            :
                            <>
                                <label className={"dialogTitle"}>Decline Transaction</label><br/>
                                <label className={"dialogDescription"}>
                                    Do you want to decline the client transaction?
                                </label>
                            </>
                        }
                    </DialogContent>
                    <br/>
                    {!isLoading ?
                        <>
                            <Button 
                                onClick={props.onhandleApproveTransaction()} 
                                className={"dialogApproveButton"}
                            >
                               {isApproveDecline === 'approve' ? ' Yes, Approve' : 'Yes, Decline'}
                            </Button>
                            <Button 
                                onClick={props.onHandleCloseDialog()}
                                className={"dialogCancelButton"}
                                >
                                Cancel
                            </Button>
                        </>
                        : 
                        <>
                            <CircularProgress className={'dialogLoading'}/>
                            <br/><br/>
                        </>
                    }  
                </Dialog>

                <Backdrop
                    style={{zIndex: '9999', color: '#0d242cbb'}}
                    open={toggleShowImage}
                    onClick={props.onCloseImage()}
                >
                    {imageType !== -100 && <img src={imgSrc} alt="" className={'imagePreview'} />}
                </Backdrop>

                {toggleAlert && (
                <Snackbar
                    anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                    autoHideDuration={3000}
                    open={toggleAlert}
                    onClose={props.onhandleClose()}
                    key={1}
                >
                    <Alert onClose={props.onhandleClose()} severity={alertType}>{alertMessage}</Alert>
                </Snackbar>
                )}
            </div>
        // </StylesProvider>
    )
}

export default ClientProfileForm