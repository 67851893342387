import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Badge, Divider, Grid, Menu, MenuItem } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { NotificationsNoneOutlined, AccountCircle } from "@material-ui/icons";
import InfiniteScroll from "react-infinite-scroll-component";
import { withFirebase } from "../Firebase";
import { AuthUserContext, withAuthorization } from "../Session";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import Helpers from "../Helpers";
import * as ROUTES from "../../constants/routes";
import Functions from "./functions";

import firebase from 'firebase/app';
import 'firebase/firestore';

import './notif.css';

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "#fff",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

class NotificationMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      items: [],
      limit: 10,
      lastVisible: null,
      notificationCount: 0,
      reachedBottom: false,
      withSeeAll: false,
      hasMore: false,
      clicked: false,
    };

    this.db = firebase.firestore();
    this.userRef = this.db.collection("users");
    this.notifRef = this.db.collection("notifications");

    this.helpers = new Helpers();
    this.functions = new Functions();
  }

  static contextType = AuthUserContext;

  async componentDidMount() {
    this.unsubNotifCount = await this.fetchNotificationCount();
    this.unsubNotif = await this.fetchData(); 
  }

  async componentWillUnmount() {
    this.unsubNotifCount && this.unsubNotifCount();
    this.unsubNotif && this.unsubNotif();
  }

  handleClick = (e) => {
    this.setState({ anchorEl: e.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  fetchNotificationCount = async () => {
    let uid = this.context.uid;

    const unsubscribeCount = await this.userRef
      .doc(uid)
      .onSnapshot((snapshot) => {
        let notifCount =
          snapshot.data().pendingNotifications === undefined
            ? 0
            : snapshot.data().pendingNotifications;

        this.setState({
          notificationCount: notifCount,
        });
      })   

      return unsubscribeCount;
  };

  fetchData = async () => {
    try{
      const { limit } = this.state;
      let uid = this.context.uid;
      let unsubscribeNotif = null;

      unsubscribeNotif = await this.notifRef
        .where("userUid", "==", uid)
        .where("role", "==", "manager")
        .orderBy("dateTime", "desc")
        .limit(limit)
        .onSnapshot(async (snapshotNotif) => {
          let lastVisible = snapshotNotif.docs[snapshotNotif.docs.length - 1];

          let items = [];
          let docData = await Promise.all(
            snapshotNotif.docs.map((doc, index) => {
              items.push(doc.data());
              items[index].docId = doc.id;
              return Promise.resolve(items[index]);
            })
          );  
          
          if (items.length === 0) {
            this.setState({ hasMore: false });
          } else {
            this.setState({
              items: docData,
              hasMore: true,
              lastVisible: lastVisible,
            });
          }
        });

      return unsubscribeNotif;
    } catch (error) {
      console.log(error);
    }
  };

  fetchMoreData = async () => {
    try {
      const { limit, reachedBottom } = this.state;
      let unsubscribeMoreNotif = null;

      if (!reachedBottom) {
        let uid = this.context.uid;

        unsubscribeMoreNotif = await this.notifRef
          .where("userUid", "==", uid)
          .where("role", "==", "manager")
          .orderBy("dateTime", "desc")
          .startAfter(this.state.lastVisible)
          .limit(limit)
          .onSnapshot(async (snapshotNotif) => {
            let lastVisible = snapshotNotif.docs[snapshotNotif.docs.length - 1];
    
            let items = [];
            let docData = await Promise.all(
              snapshotNotif.docs.map((doc, index) => {
                items.push(doc.data());
                items[index].docId = doc.id;
                return Promise.resolve(items[index]);
              })
            );  
            
            let clonedItems = [...this.state.items, ...docData];
            
            console.log(docData)
            console.log(items.length)

            this.setState({
              items: clonedItems,
              reachedBottom: docData.length === 0 ? true : false,
              hasMore: true,
              lastVisible: lastVisible,
            });
          });
      }

      return unsubscribeMoreNotif;
    } catch (error) {
      console.log(error);
    }
  };

  redirect = async (e, item) => {
    let notifCount = this.state.notificationCount;
    const { clicked } = this.state;

    switch (item.redirectType) {
      case "assignedClient":
        if (!item.read && !clicked) {
          this.setState({ clicked: true });

          this.functions
            .doReadNotification(item.docId)
            .then(() => {
              this.setState({ clicked: false });
            })

          if(this.props.history.location.pathname === ROUTES.CLIENT_PORFILE){
            this.props.history.replace({ 
              state: {user: item.redirectParameters, coach: this.context.uid, from: 'NF1'} 
            })
    
            this.props.history.push('/temp');
            this.props.history.goBack();
          } else {
            this.props.history.push({
              pathname: ROUTES.CLIENT_PORFILE,
              state: {user: item.redirectParameters, coach: this.context.uid, from: 'NF1'}
            })
          }
        } else {
          if(this.props.history.location.pathname === ROUTES.CLIENT_PORFILE){
            this.props.history.replace({ 
              state: {user: item.redirectParameters, coach: this.context.uid, from: 'NF1'} 
            })

            this.props.history.push('/temp');
            this.props.history.goBack();
          } else {
            this.props.history.push({
              pathname: ROUTES.CLIENT_PORFILE,
              state: {user: item.redirectParameters, coach: this.context.uid, from: 'NF1'}
            })
          }
        }
        break;
      case "clientTransaction":
        if (!item.read && !clicked) {
          this.setState({ clicked: true });
          const params = item.redirectParameters.split(',');

          this.functions
            .doReadNotification(item.docId)
            .then(() => {
              this.setState({ clicked: false });
            })

          if(this.props.history.location.pathname === ROUTES.CLIENT_PORFILE){
            this.props.history.replace({ 
              state: {user: params[0], coach: this.context.uid, from: 'NF2', transId: params[1], transType: params[2]} 
            })
    
            this.props.history.push('/temp');
            this.props.history.goBack();
          } else {
            this.props.history.push({
              pathname: ROUTES.CLIENT_PORFILE,
              state: {user: params[0], coach: this.context.uid, from: 'NF2', transId: params[1], transType: params[2]}
            })
          }
        } else {
          const params = item.redirectParameters.split(',');
          if(this.props.history.location.pathname === ROUTES.CLIENT_PORFILE){
            this.props.history.replace({ 
              state: {user: params[0], coach: this.context.uid, from: 'NF2', transId: params[1], transType: params[2]} 
            })

            this.props.history.push('/temp');
            this.props.history.goBack();
          } else {
            this.props.history.push({
              pathname: ROUTES.CLIENT_PORFILE,
              state: {user: params[0], coach: this.context.uid, from: 'NF2', transId: params[1], transType: params[2]}
            })
          }
        }
        break;
      default:
        if(!item.read && !clicked){
          this.setState({ clicked: true });

          this.functions
            .doReadNotification(item.docId)
            .then(() => {
              this.setState({ clicked: false });
            })

          let parentClassList = e.target.offsetParent.classList;
          if (
            parentClassList.contains("infiscroll-menu-item-active") &&
            !item.read
          ) {
            parentClassList.remove("infiscroll-menu-item-active");
            parentClassList.add("infiscroll-menu-item-inactive");
            notifCount = notifCount - 1;
            this.setState({ notificationCount: notifCount });
          }
        }
        break;
    }
  };

  render() {
    const {
      anchorEl,
      items,
      notificationCount,
      reachedBottom,
      withSeeAll,
      hasMore,
    } = this.state;

    return (
      <div>
        <Badge
          color="error"
          badgeContent={notificationCount}
          aria-controls="notification-menu"
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <NotificationsNoneOutlined />
        </Badge>
        <StyledMenu
          id="notification-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <div className="notif-header">Notifications</div>
          <Divider />
          <div className="notif-menu-item-main w-max">
            <div id="scrollableDiv">
              <InfiniteScroll
                dataLength={items.length}
                next={this.unsubMoreNotif = this.fetchMoreData}
                hasMore={hasMore}
                loader={
                  reachedBottom ? (
                    <div className="dark-label text-center">
                      You've reached the bottom
                    </div>
                  ) : (
                    <div className="notif-skeleton">
                      <Skeleton />
                      <Skeleton />
                    </div>
                  )
                }
                scrollableTarget="scrollableDiv"
              >
                {items.length > 0 ? (
                  items.map((item, index) => (
                    <StyledMenuItem
                      className={clsx(
                        "infiscroll-menu-item",
                        !item.read
                          ? "infiscroll-menu-item-active"
                          : "infiscroll-menu-item-inactive"
                      )}
                      key={index}
                      onClick={(event) => this.redirect(event, item)}
                    >
                      <Grid container spacing={2}>
                        <Grid item sm={2}>
                          <AccountCircle className="notif-acc-circle" />
                        </Grid>
                        <Grid item sm={10}>
                          <p className="notif-item-msg">{item.content}</p>
                          <div className="notif-item-date">
                            {this.helpers.timeStampFormatter(item.dateTime)}
                          </div>
                        </Grid>
                      </Grid>
                    </StyledMenuItem>
                  ))
                ) : (
                  <div className="dark-label text-center">
                    No notification available.
                  </div>
                )}
              </InfiniteScroll>
            </div>
          </div>
          {withSeeAll ? (
            <>
              <Divider />
              <div className="notif-footer text-center">
                See all notifications
              </div>
            </>
          ) : (
            ""
          )}
        </StyledMenu>
      </div>
    );
  }
}

const condition = (authUser) => !!authUser;
export default compose(
  withRouter,
  withAuthorization(condition),
  withFirebase
)(NotificationMenu);
