import React, { Component } from 'react'
import logo from '../img/logo.png'; 
import latest from '../img/latest.png'; 
import latest2 from '../img/latest2.png'; 
import latest3 from '../img/latest3.png'; 
import { TextField, Button, CircularProgress  } from '@material-ui/core';
import { withFirebase } from './Firebase';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import AlertBox from './AlertBox';
import {withoutAuthorization} from './Session';
import { withCookies } from 'react-cookie';

const SignInPage = () => (
    <SignInForm/>
);

class SignInFormBase extends Component {
    
    constructor(props) {
        super(props);
        this.state = {            
            email: "",
            password: "",
            errors: "",
            emailError: "",
            passwordError: "",
            alert_message: "This is a testing",
            alert_type: "success",
            show_alert: false,
            loading: false
        };
    }

    validate = (e) => {
        const {email, password} = this.state;
        const errors = {};
        switch(e.target.name){
            case 'email':
                if(email.indexOf('@') === -1){
                    errors.emailError = 'Invalid Email Address.';
                }
                else{
                    errors.emailError = '';
                }
                break;
            case 'password':
                if(password.length < 1){
                    errors.passwordError = 'This field is required.';
                }
                else{
                    errors.passwordError = '';
                }
                break;
            default:
                if(email.length === 0){
                    errors.emailError = 'This field is required.';
                }                   
                if(password.length === 0){
                    errors.passwordError = 'This field is required.';   
                }           
        }
        
        this.setState({
            ...this.state,
            ...errors
        })
        
        if(Object.keys(errors).length !== 0){
            return true;
        }
        else{
            return false;
        }
    }

    handleFormChange = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value}, () => {
            this.validate(e);
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const err = this.validate(e);
        const {email, password} = this.state;
        const { cookies } = this.props;
        this.setState({loading: true});
        if(!err){
            this.props.firebase.doSignInWithEmailAndPassword(email, password)
            .then((response) => {
                if(response){
                    return this.props.firebase.getCustomClaims();
                }
            })
            .then((res) => {
                if (res) {
                    if (res.claims.role === "manager") {
                        cookies.set('token', res.token, { path: '/' });
                        this.props.history.push('/');
                    }
                    else{
                        this.setState({
                            show_alert: true,
                            alert_message: 'Invalid Account.',
                            alert_type: 'warning',
                            loading: false
                        });
                        this.props.firebase.doSignOut();
                    }
                }
            })
            .catch((err) => {
                this.setState({
                    show_alert: true,
                    alert_message: err.message,
                    alert_type: 'error',
                    loading: false
                });
                setTimeout(
                    () => this.setState({ show_alert: false }), 
                    5000
                );   
            })
        }
    }


    render() {
        var buttonHide;
        const {email, password, emailError, passwordError, show_alert, loading} = this.state;      
        if(!loading){
            buttonHide = <Button variant="contained" size="large" color="primary" type="submit">Sign In</Button>;
        } else{
            buttonHide = <CircularProgress/>;
        }
        return (
            <div className="login-bg">
                <div className="login client">
                    <div className="left">
                        <br/> <br/> <br/>
                        <img src={logo} alt="logo"/>
                        <h2 className="_title">Welcome to Rampver</h2>
                        <span>Hi Coach! Welcome to Rampver</span>
                        {show_alert && <AlertBox alert_message={this.state.alert_message} alert_type={this.state.alert_type}/>}
                        <form onSubmit={this.handleSubmit} name="form">
                            <TextField
                                name="email"
                                type="text"
                                label="Email Address"
                                placeholder="Enter your email address..."
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={email}
                                variant="outlined"
                                helperText={emailError}
                                onChange={this.handleFormChange}
                                error={emailError !== '' ? true : false}
                            />
                            <TextField
                                name="password"
                                type="password"
                                label="Password"
                                placeholder="Enter your password..."
                                fullWidth
                                margin="normal"
                                value={password}
                                onChange={this.handleFormChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                helperText={passwordError}
                                variant="outlined"
                                error={passwordError !== '' ? true : false}
                            />
                            <br/> <br/>
                            {buttonHide}
                        </form>
                        <br/> <br/>
                    </div>
                </div> 
            </div>
        )
    }
}

const condition = authUser => !!authUser;

const SignInForm = compose(
    withoutAuthorization(condition),
    withRouter,
    withFirebase,
    withCookies
)(SignInFormBase);

export default SignInPage;

export {SignInForm}
