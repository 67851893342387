import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
 
import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
 
 
const withoutAuthorization = condition => Component => {
    class withoutAuthorization extends React.Component {
        state = {
            role: null
        }

        componentDidMount() {
            this.listener = this.props.firebase.auth.onAuthStateChanged(
                authUser => {
                    if(authUser){
                        this.props.firebase.getCustomClaims()
                        .then(res => {
                            if(res.claims.role === 'manager' && condition(authUser)){
                                this.setState({role: 'manager'})
                                this.props.history.push(ROUTES.LANDING);
                            }
                        })
                        .catch(err => {
                            console.log(err)
                        })
                    }
                    // if (condition(authUser)) {
                    //     this.props.history.push(ROUTES.LANDING);
                    // }
                },
            );
        }

        componentWillUnmount() {
            this.listener();
        }

        render(){
            if(this.state.role !== null){
                return (
                    <AuthUserContext.Consumer>
                        {authUser =>
                            !condition(authUser) ? <Component {...this.props} /> : null
                        }
                    </AuthUserContext.Consumer>
                );
            }
            else{
                return <Component {...this.props} />
            }
        }
    }

    return compose(
        withRouter,
        withFirebase,
      )(withoutAuthorization);
};
 
export default withoutAuthorization;