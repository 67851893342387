import axios from 'axios';
import * as ROUTES from '../../constants/routes';
import Helpers from "../Helpers";

export default class AccountFunction{
    constructor() {
        this.helpers = new Helpers();
    }

    doUpdateAccount = (useruid, email, mobileNum) => {
        const token = this.helpers.getCookie("token");
        return new Promise((resolve, reject) => {
            axios.post(
                ROUTES.FUNCTIONAL_BASE_URL + '/account-account/updateEmailPhone',
                JSON.stringify({
                    useruid: useruid,
                    email: email,
                    mobileNumber: '+63' + mobileNum,
                }),
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    }
                }
            )
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                this.helpers.handleError(err);
                reject(err);
            });
        });
    }
        
}