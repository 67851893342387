import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import DescriptionIcon from '@material-ui/icons/Description';
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import logo from "../../img/rampver-logo-inverted.png";
import { withFirebase } from "../Firebase";

import NotificationMenu from "../NotificationMenu";

import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontFamily: "Poppins",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#256141 !important',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    fontFamily: "Poppins",
    background: "white",
    height: '100%'
  },
  brand: {
    maxWidth: "140px",
    height: "80px",
    margin: "20px 45px 20px 45px",
  },
  brand2: {
    maxWidth: "140px",
    height: "35px",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  align_right: {
    position: "absolute",
    right: "25px",
  },
  menuPadding: {
    marginLeft: "13px"
  },
  menuColorActive: {
    color: "white !important",
  },
  menuColor: {
    color: "#A7C2B4 !important",
  }
}));

var navSelected = 1;

function ResponsiveDrawer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open_nav = Boolean(anchorEl);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);


  };

  const logout = () => {
    props.firebase.doSignOut();
  };

  const handleListItemClick = (event, index) => {
    navSelected = index;
  }

  const drawer = (
    <div>
      <img className={classes.brand} src={logo} alt="rampver-logo" />
      <Divider />
      <List>
        {[{text: 'Clients', icon: <AccountCircleOutlinedIcon />, route: ROUTES.LANDING},
          {text: 'Transaction List', icon: <DescriptionIcon />, route: ROUTES.TRANSACTION_LIST}
         ].map((item, index) => (
            <ListItem
              button
              key={index+1}
              selected={navSelected === (index+1)}
              onClick={(event) => handleListItemClick(event, (index+1))}
              component={Link}
              to={item.route}
            >
              <ListItemIcon
                className={navSelected === (index+1) ? `${classes.menuColorActive} ${classes.menuPadding}` : `${classes.menuColor} ${classes.menuPadding}`}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText 
                className={navSelected === (index+1) ? classes.menuColorActive : classes.menuColor}
                primary={item.text} />
            </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} color="inherit">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <img className={classes.brand2} src={logo} alt="rampver-logo" />
          <div className={classes.align_right}>
            <IconButton>
              <NotificationMenu />
            </IconButton>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircleOutlinedIcon className="rampver-color" />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open_nav}
              onClose={handleClose}
            >
              <MenuItem 
                component={Link}
                to={ROUTES.ACCOUNT}
              >
                Account
              </MenuItem>
              <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content} color="inherit">
        <div className={classes.toolbar} />
        {props.content}
      </main>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default withFirebase(ResponsiveDrawer);
