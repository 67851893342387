import React, { Component } from 'react';

import ClientListForm from './clientListFront';

import { FirebaseContext } from '../Firebase';
import firebase from 'firebase/app';
import 'firebase/firestore';

const fixPageSize = 20;
// const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const DATE_OPTIONS = {year: 'numeric', month: 'long', day: 'numeric'};

const INTIAL_STATE = {
  statusFilter: 1,
  search: '',
  pageIndex: 1,
  pageSize: fixPageSize,
  pageCount: 0,
  currentPage: 1,
  showDialog: false,
  idDelete: '',
  userDelete: '',
  deleteConfirm: false,
  adminPassword: '',
  passwordError: '',
  severity: "warning",
  messageAlert: "",
  isAddSuccess: false,
  rows: [],
  tempRows: [],
  currentUserId: '',
  clientIds: [],
}

class UserPage extends Component {
  constructor(props) {
    super(props)
  
    this.state = {...INTIAL_STATE}
    this.db = firebase.firestore();
    this.userRef = this.db.collection("users");
  }

  componentDidMount(){  
    this.getUserId();

    setTimeout(() => {
      this.filterUserList(1);    
    }, 1000);
    
  }

  componentWillUnmount() {
    this.setState = (state, callback)=>{
      return;
    };
  }

  getUserId() {
    this.setState({ clientIds: [] });

    var promise = new Promise((resolve, reject) => {
      this.props.props.firebase.getCustomClaims()
      .then((response) => {
        resolve(response.claims.user_id);
      })
      .catch((error) => {
        console.log('Get current user id error: ',error);
        reject(error);
      });
    });

    promise
      .then((response) => {
        this.setState({currentUserId: response});
      }).catch((error) => {
		  console.log(error.message);
	  });
  }


  filterUserList(statusFilter) {
    const users = [];
    this.setState({ rows: [] });
    var firestoreQuery;

    //console.log('ids: ', this.state.clientIds);

    if(statusFilter === 1){
      firestoreQuery = this.userRef.where("investmentManager.investmentManagerId", "==", this.state.currentUserId);
    } else if(statusFilter === 2){
      firestoreQuery = this.userRef.where("investmentManager.investmentManagerId", "==", this.state.currentUserId).where("hasShortKYC", "==", false);
    } else {
      firestoreQuery = this.userRef.where("investmentManager.investmentManagerId", "==", this.state.currentUserId).where("hasShortKYC", "==", true);
    }

    var promise = new Promise((resolve, reject) => {
      firestoreQuery
      .get()
      .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          if(doc.exists){
            const myDetails = doc.data();

            var userId = doc.id;

            var firstName = myDetails.firstName !== undefined ? myDetails.firstName : "";
            var lastName = myDetails.lastName !== undefined ? myDetails.lastName : "";
            var fullname = firstName.charAt(0).toUpperCase() + firstName.slice(1) + " " + lastName.charAt(0).toUpperCase() + lastName.slice(1);

            var status = myDetails.status !== undefined ? myDetails.status : false;
            var uStatus = status === true ? 1 : 0;


            var hasKYC = ((myDetails.hasShortKYC !== undefined) || (myDetails.hasLongKYC !== undefined)) ? true : false;

            //var formattedDate;
            var dateCreated = myDetails.dateCreated !== undefined 
                  ? new Date(myDetails.dateCreated.toDate()).toLocaleDateString('en-US', DATE_OPTIONS)
                  : 'N/A'

            // var dateCreated = myDetails.dateCreated !== undefined ? myDetails.dateCreated : "N/A";
            // if (dateCreated !== 'N/A') {
            //   var newDate = new Date(dateCreated.seconds * 1000);
            //   var month = monthNames[newDate.getMonth()];
            //   var date = newDate.getDate();
            //   var year = newDate.getFullYear();

            //   if((month !== undefined) && (date !== undefined) && (year !== undefined)){
            //     formattedDate = `${month} ${date}, ${year}`;
            //   } else {
            //     formattedDate = 'N/A';
            //   }
            // } else {
            //   formattedDate = 'N/A';
            // }
            
            var email = myDetails.email !== undefined ? myDetails.email : "N/A";

            var approvedLongKYC = myDetails.approvedLongKYC !== undefined ? myDetails.approvedLongKYC : false;

            users.push({
              userId: userId, 
              name: fullname, 
              status: uStatus, 
              dateCreated: dateCreated, 
              email: email, 
              hasKYC: hasKYC,
              approvedLongKYC: approvedLongKYC,
            });
          }
        });

        resolve(users);
      })
      .catch(function(error) {
        console.log(error);
        reject(error);
      });
    });

    promise
      .then(res => {
        this.setState({ 
          rows: res, 
          tempRows: res,
          pageCount: Math.ceil(users.length/fixPageSize),
          pageSize: users.length < fixPageSize ? users.length : fixPageSize,
        });
      })
      .catch(function(error) {
        console.log(error);
      });  
  }

  handleStatusChange = (event) => {
    // const statusfilter = event.target.options[event.target.selectedIndex].text;
    const statusfilter = event.target.value;

    this.filterUserList(Number(statusfilter));
    
    this.setState({
      statusfilter: statusfilter,
    });
  }

  handleInputChange = (event) => {
    this.setState({[event.target.name]: event.target.value });

    var text = event.target.value;
    var dataRes = [];

    this.state.rows.forEach(item => {
      if(item.name.toLowerCase().indexOf(text.toLowerCase()) !== -1){
        dataRes.push(item);
      }
    });

    //console.log(dataRes);

    this.setState({ 
      tempRows: dataRes,
      pageCount: Math.ceil(dataRes.length/fixPageSize),
      pageSize: dataRes.length < fixPageSize ? dataRes.length : fixPageSize,
    });
  }

  handlePageChange = (event, value) => {
    const {
      pageIndex,
      pageSize,
      pageCount,
      currentPage,
      rows,
    } = this.state;
    
    var updatedPageIndex;
    var updatedPageSize;

    value !== currentPage
      ? value > currentPage
          ? updatedPageIndex = pageIndex + (fixPageSize * (value - currentPage))
          : updatedPageIndex = pageIndex - (fixPageSize * (currentPage - value))
      : updatedPageIndex = pageIndex;

    value !== currentPage
      ? value !== pageCount
          ? updatedPageSize = fixPageSize * value
          : updatedPageSize = rows.length
      : updatedPageSize = pageSize;
      
    this.setState({
      currentPage: value,
      pageIndex: updatedPageIndex,
      pageSize: updatedPageSize,
    });
  }

  render(){
    return(
      <FirebaseContext.Consumer>
        {firebase => 
          <ClientListForm
            state={this.state}
            firebase={firebase}
            onhandleStatusChange={() => this.handleStatusChange.bind(this)}
            onhandlePageChange={() => this.handlePageChange.bind(this)}
            onhandleInputChange={() => this.handleInputChange.bind(this)}
          />
        }
      </FirebaseContext.Consumer>
    )
  }
}

export default UserPage
