import React from 'react';

import {
    Button,
    Snackbar,
    OutlinedInput,
    InputAdornment,
    FormHelperText,
    TextField,
    FormControl,
    Fade,
    Divider,
    IconButton,
    CircularProgress,
} from '@material-ui/core';

import {
    AccountCircleOutlined,
    EditOutlined,
    LockOutlined,
    Visibility,
    VisibilityOff,
} from '@material-ui/icons';

import MuiAlert from '@material-ui/lab/Alert';

import './css/account.css';
import {withRouter } from 'react-router-dom';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AccountOverview = (props) => {
    return(
        <> 
            <label className={'accountItemHeader'}>Account Overview</label>
            <br/><br/><br/>
            <label className={'accountItemTitle'}>Profile</label>

            <table className={'accountContentTable'}>
                <tbody>
                    <tr>
                        <td>Name</td>
                        <td style={{textTransform: 'capitalize'}}>{props.props.state.currentUserName}</td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>{props.props.state.currentUserEmail}</td>
                    </tr>
                    <tr>
                        <td>Mobile number</td>
                        <td>{props.props.state.currentUserNumber}
                        </td>
                    </tr>
                </tbody>
            </table>

            <Button 
                data-item={1}
                onClick={props.props.onSetSelectedItem()}
                className={'accountOverviewEditButton'}
            >
                Edit Account
            </Button>
        </>
    )
};

const EditAccount = (props) => {
    return(
        <> 
            <label className={'accountItemHeader'}>Edit Account</label>
            <br/><br/><br/>

            <form onSubmit={props.props.onHandleEditAccountSubmit()}>
                <div>
                    {/* <div className={'accountEditAccountFieldContainer'}>
                        <label className={'accountEditAccountTitle'} style={{marginBottom: 10}}>Email Address</label>
                        <TextField
                            name="email"
                            inputProps={{ className: 'accountEditAccountEmailTextbox', style:{padding: "17px 0px 17px 15px"}}}
                            variant="outlined"
                            value={props.props.state.email}
                            placeholder="Enter your email address..."
                            autoComplete="off"
                            required
                            onChange={props.props.onHandleInputChange()}
                        />
                        <Fade in={true}>
                            <FormHelperText style={{ color: "red" }}>
                                {props.props.state.emailError}
                            </FormHelperText>
                        </Fade>
                    </div>
                    <br/> */}
                    <div className={'accountEditAccountFieldContainer'}>
                        <label className={'accountEditAccountTitle'} style={{marginBottom: 10}}>Mobile Number</label>
                        <FormControl>
                            <OutlinedInput
                                name="mobileNum"
                                value={props.props.state.mobileNum}
                                className={'accountEditAccountNumTextbox'}
                                inputProps={{ maxLength: 10,
                                    style:{padding: "17px 0px 17px 0px"}}}
                                required
                                autoComplete="off"
                                startAdornment={
                                    <InputAdornment position="start"><label className={'accountEditAccountNumText'}>+63</label></InputAdornment>
                                }
                                onChange={props.props.onMobileNumKeyUp()}
                            />
                        </FormControl>
                        <Fade in={true}>
                            <FormHelperText style={{ color: "red" }}>
                                {props.props.state.mobileNumError}
                            </FormHelperText>
                        </Fade>
                    </div>
                </div>
                
                <br/>
                <br/>
                <Divider />
                
                {!props.props.state.isLoading ?
                    <Button
                        type="submit"
                        className={'accountEditAccountSaveButton'}
                    >
                        Save Changes
                    </Button>
                    : <CircularProgress style={{marginTop: 40, marginLeft: 40}} />
                }
            </form>
        </>
    )
};

const ChangePassword = (props) => {
    return(
        <> 
            <label className={'accountItemHeader'}>Change Password</label>
            <br/><br/><br/>
            
            <form onSubmit={props.props.onHandleChangePassSubmit()}>
                <div>
                    <div className={'accountEditAccountFieldContainer'}>
                        <label className={'accountEditAccountTitle'} style={{marginBottom: 10}}>Current Password</label>
                        <TextField
                            name="currentPass"
                            type={props.props.state.currentPassVisible ? "text" : 'password'}
                            inputProps={{ className: 'accountEditAccountEmailTextbox', style:{padding: "17px 0px 17px 15px"}}}
                            variant="outlined"
                            value={props.props.state.currentPass}
                            autoComplete="off"
                            required
                            onChange={props.props.onHandleInputChange()}
                            InputProps={{
                                endAdornment:
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="visibility"
                                      data-name="currentPassVisible"
                                      onClick={props.props.onHandlePasswordVisibility()}
                                    >
                                      {props.props.state.currentPassVisible ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                  </InputAdornment>
                              }}
                        />
                        <Fade in={true}>
                            <FormHelperText style={{ color: "red", }}>
                                {props.props.state.currentPassError}
                            </FormHelperText>
                        </Fade>
                    </div>
                    <br/>
                    <div className={'accountEditAccountFieldContainer'}>
                        <label className={'accountEditAccountTitle'} style={{marginBottom: 10}}>New Password</label>
                        <TextField
                            name="newPass"
                            type={props.props.state.newPassVisible ? "text" : 'password'}
                            inputProps={{ className: 'accountEditAccountEmailTextbox', style:{padding: "17px 0px 17px 15px"}}}
                            variant="outlined"
                            value={props.props.state.newPass}
                            autoComplete="off"
                            required
                            onChange={props.props.onHandleInputChange()}
                            InputProps={{
                                endAdornment:
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="visibility"
                                      data-name="newPassVisible"
                                      onClick={props.props.onHandlePasswordVisibility()}
                                    >
                                      {props.props.state.newPassVisible ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                  </InputAdornment>
                              }}
                        />
                        <Fade in={true}>
                            <FormHelperText style={{ color: "red" }}>
                                {props.props.state.newPassError}
                            </FormHelperText>
                        </Fade>
                    </div>
                    <br/>
                    <div className={'accountEditAccountFieldContainer'}>
                        <label className={'accountEditAccountTitle'} style={{marginBottom: 10}}>Confirm Password</label>
                        <TextField
                            name="confirmPass"
                            type={props.props.state.confirmPassVisible ? "text" : 'password'}
                            inputProps={{ className: 'accountEditAccountEmailTextbox', style:{padding: "17px 0px 17px 15px"}}}
                            variant="outlined"
                            value={props.props.state.confirmPass}
                            autoComplete="off"
                            required
                            onChange={props.props.onHandleInputChange()}
                            InputProps={{
                                endAdornment:
                                  <InputAdornment position="end"
                                  >
                                    <IconButton
                                      aria-label="visibility"
                                      data-name="confirmPassVisible"
                                      onClick={props.props.onHandlePasswordVisibility()}
                                    >
                                      {props.props.state.confirmPassVisible ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                  </InputAdornment>
                              }}
                            
                        />
                        <Fade in={true}>
                            <FormHelperText style={{ color: "red" }}>
                                {props.props.state.confirmPassError}
                            </FormHelperText>
                        </Fade>
                    </div>
                </div>
                
                <br/>
                <br/>
                <Divider />

                {!props.props.state.isLoading ?
                    <Button
                        type="submit"
                        className={'accountEditAccountSaveButton'}
                    >
                        Save Changes
                    </Button>
                    : <CircularProgress style={{marginTop: 40, marginLeft: 40}} />
                }
            </form>
        </>
    )
};

const AccountForm = (props) => {
    const {
        selectedItem,
        toggleAlert,
        alertType,
        alertMessage,
    } = props.state;

    return(
        <div className={'accountRoot'}>
            <div className={'accountContainerLeft'}>
                <Button onClick={() => props.history.goBack()} className={'accountBackButton'}>{`< Back`}</Button>
                {[{text: 'Account Overview', icon: <AccountCircleOutlined className={'accountSideIcon'}/>},
                  {text: 'Edit Account', icon: <EditOutlined className={'accountSideIcon'}/>},
                  {text: 'Change Password', icon: <LockOutlined className={'accountSideIcon'}/>},
                ].map((item, index) => (
                    <div
                        key={index}
                        data-item={index}
                        onClick={props.onSetSelectedItem()}
                        className={selectedItem === (index) ? 'accountSideButtonActive' : 'accountSideButton'}
                    >
                        {item.icon}
                        {item.text}
                    </div>
                ))}
            </div>
            <div className={'accountContainerRight'}>
                {
                    {
                        0:<AccountOverview props={props} />,
                        1:<EditAccount props={props} />,
                        2:<ChangePassword props={props} />
                    }[selectedItem]

                }
            </div>

            {toggleAlert && (
            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                autoHideDuration={3000}
                open={toggleAlert}
                onClose={props.onHandleSnackbarClose()}
                key={1}
            >
                <Alert onClose={props.onHandleSnackbarClose()} severity={alertType}>{alertMessage}</Alert>
            </Snackbar>
            )}
        </div>
    )
}

export default withRouter(AccountForm)