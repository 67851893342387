import React, { Component } from 'react';

// import Typography from '@material-ui/core/Typography';

import ClientProfileFunction from './clientProfileFunction';
import { withFirebase } from '../Firebase';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import withAuthorization from '../Session/withAuthorization';

import NavigationMenus from '../NavigationMenus';

const ClientProfilePage = () => {
    return (
        <NavigationMenus content={<ClientProfileForm />} />
    )
}

class ClientProfilePageBase extends Component {
    render(){
        return(
            <>
            <ClientProfileFunction props={this.props} />
            <label 
                variant="caption"
                style={{display: 'block', marginBottom: 'auto', textAlign: 'center', fontSize: '12px'}}
            >
                Copyright &copy; {(new Date().getFullYear())} RAMPVER. All rights reserved.
            </label>
            </>
        );
    }
}

const condition = (authUser) => !!authUser;

const ClientProfileForm = compose(
    withAuthorization(condition),
    withRouter,
    withFirebase
  )(ClientProfilePageBase);

export default ClientProfilePage;

export { ClientProfileForm };