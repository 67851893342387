import React, { Component } from 'react'
import { withAuthorization } from '../components/Session';
import {withFirebase} from './Firebase';
import {compose} from 'recompose';
 
const HomePage = (props) => {
    return (
        <Home/>
    )
}

class HomePageBase extends Component {
    logout = (e) => {
        this.props.firebase.doSignOut()
    }

    render(){
        return(
            <div>
                <h1>Home Page</h1>
                <p>The Home Page is accessible by every signed in user.</p>
                <button onClick={this.logout}>Logout</button>
            </div>
        )
    }
}
 
const condition = authUser => !!authUser;

const Home = compose(
    withAuthorization(condition),
    withFirebase
)(HomePageBase)
 
export default HomePage;

// export default HomePageBase;

export { Home }
