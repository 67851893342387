import * as ROUTES from "../../constants/routes";
import axios from "axios";
import Helpers from "../Helpers";

export default class Functions {
  constructor() {
    this.helpers = new Helpers();
  }

  doReadNotification = (notifId) => {
    const token = this.helpers.getCookie("token");
    return new Promise((resolve, reject) => {
      axios.post(
        `${ROUTES.FUNCTIONAL_BASE_URL}/notification-notifications/readNotification`,
        JSON.stringify({ notificationUid: notifId }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        this.helpers.handleError(err);
        reject(err);
      });
    });
  }
    
}
