import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import withAuthorization from '../Session/withAuthorization';
import NavigationMenus from '../NavigationMenus';
import CustomDataTable from '../CustomDataTable';

// import * as ROUTES from '../../constants/routes';

import {
    Grid,
} from "@material-ui/core";
import './css/transactionlist.css';

import firebase from 'firebase/app';
import 'firebase/firestore';

const TransactionListPage = () => {
    return(
        <NavigationMenus content={<TransactionList />} />
    );
};

class TransactionListBase extends Component {
    constructor(props){
        super(props);
        this.state = {
            columns: [
                {
                    name: "account_id",
                    label: "Account ID",
                    options: {
                        filter: true,
                        sort: true,
                    }
                },
                {
                    name: "first_name",
                    label: "First Name",
                    options: {
                        filter: true,
                        sort: true,
                    }
                },
                {
                    name: "last_name",
                    label: "Last Name",
                    options: {
                        filter: true,
                        sort: true,
                    }
                },
                   {
                    name: "date_created",
                    label: "Date Created",
                    options: {
                        filter: true,
                        sort: true,
                    }
                },
                {
                    name: "email",
                    label: "Email",
                    options: {
                        filter: true,
                        sort: true,
                    }
                },
                {
                    name: "trans_id",
                    label: "Transaction ID",
                    options: {
                        filter: true,
                        sort: true,
                    }
                },
                {
                    name: "company",
                    label: "Company",
                    options: {
                        filter: true,
                        sort: true,
                    }
                },
                {
                    name: "status",
                    label: "Status",
                    options: {
                        filter: true,
                        sort: true,
                    }
                }
            ],
            transactions_list: [],
            options: {
                filterType: 'checkbox',
                responsive: 'standard',
                selectableRowsHideCheckboxes: true,
                selectableRowsHeader: false,
                selectableRows: false,
            },
            currentUserId: '',
        }
        this.db = firebase.firestore();
    }

    componentDidMount(){
        this.getUserId();

        setTimeout(() => {
            var transactions_list = [];

            this.db.collection("transactions")
                .orderBy("datePlaced", "desc")
                .where("investmentManager.investmentManagerId", "==", this.state.currentUserId)
                .get()
                .then((snapshot) => {
                    snapshot.docs.forEach(function(doc){
                        if(doc.exists){
                            var id = doc.id
                            transactions_list.push({
                                account_id: doc.data().clientDetails.accountNumber,
                                first_name: doc.data().clientDetails.userFirstName,
                                last_name: doc.data().clientDetails.userLastName,
                                date_created: doc.data().datePlaced === undefined ? "" : doc.data().datePlaced.toDate().toDateString(),
                                email: doc.data().clientDetails.userEmail,
                                trans_id: id,
                                status: (doc.data().status.charAt(0).toUpperCase() + doc.data().status.slice(1)).replace(/([a-z](?=[A-Z]))/g, '$1 '),
                                company: doc.data().fundCompanyDetails.fundCompanyName
                            });
                        }
                    });
                    this.setState({transactions_list: transactions_list});
                })
                .catch(err => {
                    console.log(err)
                });
        }, 2000);
    }

    getUserId() {
        this.setState({ clientIds: [] });
    
        var promise = new Promise((resolve, reject) => {
            this.props.firebase.getCustomClaims()
          .then((response) => {
            resolve(response.claims.user_id);
          })
          .catch((error) => {
            console.log('Get current user id error: ',error);
            reject(error);
          });
        });
    
        promise
          .then((response) => {
            this.setState({
                currentUserId: response
            })
          });
    }
    
    render() {
        document.body.style.backgroundColor = "white";
        return (
            <div className="userListRoot">     
                <div className="body-content">
                    <div className="transaction-list">
                        <h1>Transactions</h1>
                        <span>
                            Consist of all investors/clients list and application
                        </span>
                        <br/> <br/> <br/> 
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CustomDataTable
                                    title={"Transactions List"}
                                    data={this.state.transactions_list}
                                    columns={this.state.columns}
                                    options={this.state.options}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        )
    }
}

const condition = authUser => !!authUser;

const TransactionList = compose(
    withAuthorization(condition),
    withRouter,
    withFirebase
)(TransactionListBase);

export default TransactionListPage;

export { TransactionList };

