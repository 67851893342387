import Firebase from "../Firebase/firebase";

export default class Helpers {
    constructor() {
      this.firebase = new Firebase();
    }

    timeStampFormatter = (timeStamp) => {
      var d = new Date(timeStamp.toDate());
      var dateString = timeStamp.toDate().toDateString();
      
      var hours = d.getHours();
      var minutes = d.getMinutes();
      var time = this.tConvert(hours, minutes);
  
      return `${dateString} ${time}`;
    };
  
    tConvert = (hours, minutes) => {
      var AmOrPm = hours >= 12 ? "pm" : "am";
      hours = hours % 12 || 12;
      var finalTime =
        hours + ":" + (minutes > 9 ? minutes : `${0}${minutes}`) + " " + AmOrPm;
      return finalTime;
    };
  
    formatToPHP = (value) => {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "PHP",
        minimumFractionDigits: 2,
      });
  
      return formatter.format(value);
    };
  
    numberCommaSeparated = (value) => {
      const formatter = new Intl.NumberFormat("en-US");
  
      return formatter.format(value);
    };

    getCookie = (cname) => {
      var name = cname + "=";
      var ca = document.cookie.split(';');
      for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }

    handleError = (error) => {
      const status = error.response.status;
      if (status === 401 || status === 403) {
        if(status === 401) window.location.href = "/invalid";
        else alert(error.response.data.message);
      }
    };
  }
  