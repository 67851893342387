import React, { Component } from 'react';

// import Typography from '@material-ui/core/Typography';

import AccountFunction from './accountFunction';
import { withFirebase } from '../Firebase';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import withAuthorization from '../Session/withAuthorization';

import NavigationMenus from '../NavigationMenus';

const AccountPage = () => {
    return (
        <NavigationMenus content={<AccountForm />} />
    )
}

class AccountPageBase extends Component {
    render(){
        return(
            <>
            <AccountFunction props={this.props} />
            <label 
                variant="caption"
                style={{
                    display: 'block',
                     marginBottom: 'auto', 
                     textAlign: 'center', 
                     fontSize: '12px'}}
            >
                Copyright &copy; {(new Date().getFullYear())} RAMPVER. All rights reserved.
            </label>
            </>
        );
    }
}

const condition = (authUser) => !!authUser;

const AccountForm = compose(
    withAuthorization(condition),
    withRouter,
    withFirebase
  )(AccountPageBase);

export default AccountPage;

export { AccountForm };