import React from 'react';

import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

import {
    // Button,
    FormControl,
    Select,
    InputAdornment,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from '@material-ui/core';

import {
    Search,
    FiberManualRecord,
} from '@material-ui/icons';

import clsx from 'clsx';
import { 
  makeStyles, 
  withStyles, 
  // StylesProvider 
} from '@material-ui/core/styles';
import './css/clientList.css';

import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) => ({
    inlineDisplay: {
      right: 0,
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 20
    },
}));
  
const StyledTableCell = withStyles((theme) => ({ 
    head: {
      backgroundColor: '#256141',
      color: theme.palette.common.white,
      fontSize: '14px !important',
      fontWeight: 'bold',
      height: '65px !important',
      "@media only screen and (max-width: 1366px)" : {
        fontSize: '12px !important',
        height: '20px !important'
      },
      "@media only screen and (max-width: 599px)" : {
        fontSize: '10px !important',
      },
    },
    body: {
      fontSize: '14px !important', 
      height: '65px !important',
      cursor: 'pointer',
      "@media only screen and (max-width: 1366px)" : {
        fontSize: '12px !important',
        height: '20px !important',
      },
      "@media only screen and (max-width: 599px)" : {
        fontSize: '10px !important',
      },
    },
}))(TableCell);
  
const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
      },
      textDecoration: 'none !important',
    },
}))(TableRow);
  
const CssTextField = withStyles({
    root: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#C4C4C4',
        },
        '&:hover fieldset': {
          borderColor: 'black',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#C8932A',
        },
        fontSize: 15,
        "@media only screen and (max-width: 1366px)" : {
          fontSize: 12,
        },
        textTransform: 'capitalize !important',
      },
    },
})(TextField);

const ClientListForm = (props) => {
  const classes = useStyles();

  const {
    search,
    pageIndex,
    pageSize,
    pageCount,
    currentPage,
    tempRows,
    currentUserId,
  } = props.state;
  
  return (
    // <StylesProvider injectFirst>
      <div className={"clientListRoot"}> 
        <div className={"clientListpaper"}>
          <label className={"clientListtitleText"}>Client List</label>

          <label className={"clientListdescription"}>
            Consist of all clients including <br/>
            approve and pending clients.
          </label>

          <label className={"clientListlabelUser"}>Display User</label>

          <FormControl variant="outlined" size="small" className={"clientListformControl"}>
            <Select native
              onChange={props.onhandleStatusChange()}>
                {['All', 'Pending', 'Approved'].map((item, index) => (
                  <option key={item} value={index+1}>{item}</option>
                ))}
            </Select>
          </FormControl>
        </div>    
        <div className={"clientListpaper2"}>
          <FormControl className={clsx("clientListtextField")} variant="outlined" size="small">
            <CssTextField
              name="search"
              variant="outlined"
              size="small"
              id="search"
              value={search}
              className={"clientListTextFont"}
              onChange={props.onhandleInputChange()}
              placeholder="Search name..."
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="search"
                    >
                      <Search/>
                    </IconButton>
                  </InputAdornment>
              }}
            />
          </FormControl>
                    
          <TableContainer>
            <Table className={"clientListtableWidths"} aria-label="users table">
              <TableHead>
                <TableRow>
                  {[ 
                    {name: 'Name', width: 150},
                    {name: 'Email', width: 150},
                    {name: 'Date Created', width: 150},
                    {name: 'Appplication Status', width: 150},
                    {name: 'Status', width: 100},
                   ].map((item, index) =>(
                      <StyledTableCell key={index} align="center">{item.name}</StyledTableCell>
                   ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tempRows.length > 0 ? 
                  tempRows.map((row, index) => (
                    (row.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) &&
                    (((index+1) >= pageIndex) && ((index+1) <= pageSize)) &&
                      <StyledTableRow 
                        key={row.userId} 
                        component={Link} 
                        to={{pathname: row.hasKYC ? ROUTES.CLIENT_PORFILE : '', 
                            state:{user: row.userId, coach: currentUserId, from: 'CL'}}
                          }
                      >
                        <StyledTableCell align="center">
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.email}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.dateCreated}
                        </StyledTableCell>
                        <StyledTableCell 
                          align="center" 
                          style={row.approvedLongKYC ? {color: '#1AB071'} : {color: '#C8932A'}}
                        >
                          <FiberManualRecord style={{fontSize: 12}}/>&nbsp;
                          {row.approvedLongKYC ? 'Approved' : 'Pending'}
                        </StyledTableCell>
                        <StyledTableCell 
                          align="center" 
                          style={row.status === 1 ? {color: '#1AB071'} : {color: '#9F9F9F'}}
                        >
                          <FiberManualRecord style={{fontSize: 12}}/>&nbsp;
                          {row.status === 1 ? 'Active' : 'Inactive'}
                        </StyledTableCell>
                      </StyledTableRow>
                    )) :
                    <StyledTableRow>
                      <StyledTableCell colSpan="4" align="center">
                        Client List Empty
                      </StyledTableCell>
                    </StyledTableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>

          <div className={classes.inlineDisplay}>
            <br/>
            <label variant="caption" style={{float: 'left', "@media only screen and (max-width: 599px)" : {
                  fontSize: '10px !important',},}}
            >
              <strong>
                {tempRows.length > 0
                  ? `Showing ${pageIndex} to ${pageSize} of ${tempRows.length} entries`
                  : 'Showing 0 to 0 of 0 entries'
                }
              </strong>
            </label>
                
            <div style={{float: 'right', marginTop: -8}}>
              <Pagination 
                className={"clientListpagination"} 
                color="primary" 
                count={pageCount} 
                size="small" 
                shape="rounded"
                page={currentPage}
                onChange={props.onhandlePageChange()} />
            </div> 
          </div>
        </div> 
      </div>
    // </StylesProvider>
  );
}

export default ClientListForm