import React from "react";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

function CustomDataTable(props){
    const getMuiTheme = createMuiTheme({
        overrides: {
            MUIDataTableHeadCell: {
                root: {
                    fontFamily: 'Poppins !important',
                    background: '#256141 !important',
                    color: 'white !important',
                    fontSize: '16px',
                    borderTop: '1px solid white',
                    '& span div div span svg': {
                        color: 'white !important',
                    },
                    '& span div': {
                        color: 'white !important',
                    }
                }
            },
            MUIDataTableBodyCell: {
              root: {
                cursor: 'pointer',
                fontFamily: 'Poppins !important'
              }
            },
            MUIDataTableBodyRow: {
                root: {
                    '&:nth-child(odd)': { 
                        backgroundColor: 'rgba(37, 97, 65, 0.1)'
                    },
                    '&:nth-child(odd):hover': {
                        backgroundColor: 'rgba(37, 97, 65, 0.1) !important'
                    },
                    '&:nth-child(even):hover': {
                        backgroundColor: 'white'
                    },
                }
            },
            MUIDataTableToolbar: {
                root:{
                    background: '#256141',
                    color: 'white',
                    '& button':{
                        color: 'white',
                    },
                    '& h6':{
                        fontFamily: 'Poppins !important'
                    },
                    '& div svg':{
                        color: 'white',
                    },
                    '& input':{
                        color: 'white',
                    }
                },
            }
        }
    });
    return(
        <MuiThemeProvider theme={getMuiTheme}>
            <MUIDataTable
                title={props.title}
                data={props.data}
                columns={props.columns}
                options={props.options}
            />
        </MuiThemeProvider>
    );
}

export default CustomDataTable;