import React, { Component } from 'react'

import ClientProfileFront from './clientProfileFront'

import { withRouter } from 'react-router-dom'
// import * as ROUTES from '../../constants/routes';

import { FirebaseContext } from '../Firebase'
import firebase from 'firebase/app';
import Helpers from '../Helpers';
import 'firebase/firestore';
import 'firebase/storage';

import ClientProfileFunc from './clientProfileFunc';

const INITIAL_STATE = {
    tabValue: 1,
    itemSelected: 1,
    kycPage: 1,
    clientId: '',
    FATCA: [],
    PEP: [],
    annualIncome: [],
    businessAdd: [],
    civil: [],
    contact: [],
    currentAdd: [],
    dateOfBirth: '',
    placeOfBirth: '',
    email: '',
    gender: '',
    idPhoto: '',
    idPhotoFront: '',
    idPhotoBack: '',
    proofOfAddress: '',
    PEPDocument: '',
    ids: [],
    investmentManager: [],
    mobileNumber: '',
    name: [],
    nationality: '',
    otherProducts: '',
    permanentAdd: [],
    regularBasis: '',
    selfie: '',
    signature: '',
    sustainabilityAssessment: [],
    validated: false,
    validationDate: '',
    work: [],
    accountDetails: [],
    clientDetails: [],
    idPhotoSrc: '',
    idPhotoFrontSrc: '',
    idPhotoBackSrc: '',
    selfieSrc: '',
    proofOfAddressSrc: '',
    PEPDocumentSrc: '',
    imageType: -100,
    investmentDetails: [],
    transSelectedId: '',
    isLoading: false,
    toggleDialog: false,
    isApproveDecline: 'approve',
    depositSlipSrc: '',
    transType: 'pending',
    totalInvestmentAmount: 0,
    selfieSize: 0,
    idPhotoFrontSize: 0,
    idPhotoBackSize: 0,
    proofOfAddressSize: 0,
    PEPDocumentSize: 0,
    toggleShowImage: false,
    toggleAlert: false,
    alertType: 'warning',
    alertMessage: '',
  }

class ClientProfilePage extends Component {
    constructor(props) {
        super(props)

        this.state = {...INITIAL_STATE};
        this.db = firebase.firestore();
        this.storage = firebase.storage().ref();
        this.clientProfileFunction = new ClientProfileFunc();
    }

    async componentDidMount() {
        var redirectFrom = this.props.location.state.from.toUpperCase();
        await this.getKYCs();

        if(redirectFrom !== 'CL' && redirectFrom !== 'NF1'){
            this.setState({
                tabValue: 2,
                transSelectedId: this.props.location.state.transId,
                transType: this.props.location.state.transType,
            })
        }
    }

    async getKYCs() {
        var kyc = [];
        const userId = this.props.location.state.user;

        await this.db.collection("users").doc(userId).get()
        .then((result) => {
            this.setState({clientDetails: result.data()})
            return this.db.collection("users").doc(userId).collection("KYC").doc("KYC").get();
        })
        .then((querySnapshot) => {
            if (querySnapshot.exists) {
                const kycs = querySnapshot.data();
                kyc.push(kycs);
            } else {
                this.setState({
                    toggleAlert: true,
                    alertType: "error",
                    alertMessage: "KYC doesn't exists.",
                });
            }
            if (kyc.length > 0) {
                const data = kyc[kyc.length - 1];
                this.setState({
                    clientId: userId,
                    FATCA: data.FATCA !== undefined ? data.FATCA : [],
                    PEP: data.PEP !== undefined ? data.PEP : [],
                    annualIncome:
                        data.annualIncome !== undefined
                            ? data.annualIncome
                            : [],
                    businessAdd:
                        data.businessAddress !== undefined
                            ? data.businessAddress
                            : [],
                    civil: data.civil !== undefined ? data.civil : [],
                    contact: data.contact !== undefined ? data.contact : [],
                    currentAdd:
                        data.currentAddress !== undefined
                            ? data.currentAddress
                            : [],
                    dateOfBirth:
                        data.dateOfBirth !== undefined
                            ? data.dateOfBirth
                            : "N/A",
                    placeOfBirth:
                        data.placeOfBirth !== undefined
                            ? data.placeOfBirth
                            : "N/A",  
                    email: data.email !== undefined ? data.email : "N/A",
                    gender: data.gender !== undefined ? data.gender : "N/A",
                    idPhoto: data.idPhoto !== undefined ? data.idPhoto : "N/A",
                    idPhotoFront:
                        data.idPhotoFront !== undefined
                            ? data.idPhotoFront
                            : "N/A",
                    idPhotoBack:
                        data.idPhotoBack !== undefined
                            ? data.idPhotoBack
                            : "N/A",
                    proofOfAddress:
                            data.proofOfAddress !== undefined
                                ? data.proofOfAddress
                                : "N/A",
                    PEPDocument:
                            data.PEPDocument !== undefined
                                ? data.PEPDocument
                                : "N/A",
                    ids: data.ids !== undefined ? data.ids : [],
                    investmentManager:
                        data.investmentManager !== undefined
                            ? data.investmentManager
                            : [],
                    mobileNumber:
                        data.mobileNumber !== undefined
                            ? data.mobileNumber
                            : "N/A",
                    name: data.name !== undefined ? data.name : [],
                    nationality:
                        data.nationality !== undefined
                            ? data.nationality
                            : "N/A",
                    otherProducts:
                        data.otherProducts !== undefined
                            ? data.otherProducts
                            : "N/A",
                    permanentAdd:
                        data.permanentAddress !== undefined
                            ? data.permanentAddress
                            : [],
                    regularBasis:
                        data.regularBasis !== undefined
                            ? data.regularBasis
                            : "N/A",
                    selfie: data.selfie !== undefined ? data.selfie : "N/A",
                    signature:
                        data.signature !== undefined ? data.signature : "N/A",
                    sustainabilityAssessment:
                        data.sustainabilityAssessment !== undefined
                            ? data.sustainabilityAssessment
                            : [],
                    validated:
                        data.validated !== undefined
                            ? data.validated
                            : false,
                    validationDate:
                        data.validationDate !== undefined
                            ? data.validationDate
                            : "N/A",
                    work: data.work !== undefined ? data.work : [],
                    selfieSize: data.selfieSize !== undefined ? data.selfieSize : 0,
                    idPhotoFrontSize: data.idPhotoFrontSize !== undefined ? data.idPhotoFrontSize : 0,
                    idPhotoBackSize: data.idPhotoBackSize !== undefined ? data.idPhotoBackSize : 0,
                    proofOfAddressSize: data.proofOfAddressSize !== undefined ? data.proofOfAddressSize : 0,
                    PEPDocumentSize: data.PEPDocumentSize !== undefined ? data.PEPDocumentSize : 0,
                });  
            } else {
                this.setState({
                    toggleAlert: true,
                    alertType: "error",
                    alertMessage: "Unable to retrieve client informations.",
                });
            }
        })
        .catch((error) => {
            this.setState({
                toggleAlert: true,
                alertType: "error",
                alertMessage: "Unable to retrieve client KYC.",
            });
        })

        await this.getClientAccountDetails();
        await this.getClientUploadedIds();
        await this.getClientTransaction();
    }

    async getClientAccountDetails(){
        var accountDetails = [];
        const userId = this.props.location.state.user;

         await this.db.collection("users/" + userId + "/accountDetails")
            .get()
            .then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    if(doc.exists){
                        accountDetails.push(doc.data());
                    } else {
                        this.setState({
                            toggleAlert: true,
                            alertType: 'error',
                            alertMessage: 'Unable to retrieve account details',
                        });
                    }
                });

                return accountDetails[0]
            })
            .then((response) => {
                this.setState({
                    accountDetails: response,
                });
            })
            .catch((error) => {
                this.setState({
                    toggleAlert: true,
                    alertType: 'error',
                    alertMessage: 'Unable to retrieve account details',
                });
            });     
    }

    async getClientUploadedIds(){
        var idsDetails = [{ idName: this.state.selfie, stateName: 'selfieSrc', errMessage: 'selfie' },
        { idName: this.state.idPhotoFront, stateName: 'idPhotoFrontSrc', errMessage: 'idPhotoFront' },
        { idName: this.state.idPhotoBack, stateName: 'idPhotoBackSrc', errMessage: 'idPhotoBack' },
        { idName: this.state.proofOfAddress, stateName: 'proofOfAddressSrc', errMessage: 'proofOfAddress' },
        { idName: this.state.PEPDocument, stateName: 'PEPDocumentSrc', errMessage: 'PEPDocument' }];

        idsDetails.forEach(async (item) => {
            if(item.idName !== 'N/A'){
                await this.storage.child(item.idName).getDownloadURL()
                .then((url) => {
                    this.setState({
                        [item.stateName]: url,
                    });
                })
                .catch((error) => {
                    this.setState({
                        toggleAlert: true,
                        alertType: 'error',
                        alertMessage: `Unable to retrieve client ${item.errMessage} photo.`,
                    });
                })
            } else {
                this.setState({
                    toggleAlert: true,
                    alertType: 'error',
                    alertMessage: `Unable to retrieve client ${item.errMessage} photo.`,
                });
            }
        })
    }

    async getClientTransaction() {
        var investmentDetails = []
        var totalAmountInvestment = 0;
        var user_details;
        const userId = this.props.location.state.user;

        var investRef = this.db.collection("transactions").where("clientDetails.userUid", "==", userId).orderBy("datePlaced", "desc");

        var userRef = this.db.collection("users").doc(userId)
        var helpers = new Helpers();

        await userRef.get()
        .then((result) => {
            user_details = result.data();
            return investRef.get()
        })
        .then((querySnapshot) => {
            querySnapshot.forEach(function (doc) {
                if (doc.exists) {
                    const details = doc.data();

                    var transId =
                        details.eventId !== undefined
                            ? details.eventId
                            : "N/A";
                    var invAmount =
                        details.amount !== undefined
                            ? details.amount
                            : 0;
                    var invApprovedBy =
                        details.approvedBy !== undefined
                            ? details.approvedBy
                            : [];
                    var invClientDetails =
                        details.clientDetails !== undefined
                            ? details.clientDetails
                            : [];
                    var invDateMaturity =
                        details.dateMaturity !== undefined
                            ? details.dateMaturity
                            : "N/A";
                    var invDatePlaced =
                        details.datePlaced !== undefined
                            ? details.datePlaced
                            : "N/A";
                    var invFundCompDetails =
                        details.fundCompanyDetails !== undefined
                            ? details.fundCompanyDetails
                            : [];
                    var invFundDetails =
                        details.fundDetails !== undefined
                            ? details.fundDetails
                            : [];
                    var invRateType =
                        details.rateType !== undefined
                            ? details.rateType
                            : "N/A";
                    var invStatus =
                        details.status !== undefined
                            ? details.status.toLowerCase()
                            : "pending";

                    var depositSlip =
                        details.depositSlip !== undefined
                            ? details.depositSlip
                            : "N/A";

                    var folioNumber = details.folio !== "" && details.value !== undefined
                            ? details.folio
                            : "N/A";

                    var shares = details.shares !== "" && details.value !== undefined
                        ? helpers.numberCommaSeparated(details.shares)
                        : "N/A";

                    var navps = details.rateAtBooking !== "" && details.value !== undefined
                        ? helpers.formatToPHP(details.rateAtBooking)
                        : "N/A";

                    var fee = details.fee !== "" && details.value !== undefined
                        ? helpers.formatToPHP(details.fee)
                        : "N/A";

                    var total_value = details.value !== "" && details.value !== undefined
                        ? helpers.numberCommaSeparated(details.value)
                        : "N/A";

                    var depositSlipSize =
                        details.depositSlipSize !== undefined
                            ? details.depositSlipSize
                            : 0;

                    totalAmountInvestment = totalAmountInvestment + invAmount;

                    investmentDetails.push({
                            transId: transId,
                            amount: invAmount,
                            approvedBy: invApprovedBy,
                            clientDetails: invClientDetails,
                            dateMaturity: invDateMaturity,
                            datePlaced: invDatePlaced,
                            fundCompDetails: invFundCompDetails,
                            fundDetails: invFundDetails,
                            rateType: invRateType,
                            status: invStatus,
                            depositSlip: depositSlip,
                            folioNumber: folioNumber,
                            shares: shares,
                            navps: navps,
                            fee: fee,
                            accountNumber: user_details.accountNumber,
                            total_value: total_value,
                            depositSlipSize: depositSlipSize,
                    })
                } 
            });
            return {
                investmentDetails : investmentDetails,
                total: totalAmountInvestment
            };
        })
        .then((response) => {
            var transSelectedId = response.investmentDetails.length > 0 ? response.investmentDetails[0].transId : 0;
            var transType = response.investmentDetails.length > 0 ? response.investmentDetails[0].status : 'pending';

            this.setState({
                totalInvestmentAmount: response.total,
                investmentDetails: response.investmentDetails,
                transSelectedId: transSelectedId,
                transType: transType,
            });
        })
        .catch((error) => {
            this.setState({
                toggleAlert: true,
                alertType: 'error',
                alertMessage: error.message,
            });
        })
    }

    approveTransaction(transactionId, coachName, coachId){
        let promise = new Promise((resolve, reject) => {
            this.clientProfileFunction
                .doApproveTransaction(transactionId, coachName, coachId)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });

        return promise;
    }

    declineTransaction(transactionId){
        let promise = new Promise((resolve, reject) => {
            this.clientProfileFunction
                .doDeclineTransaction(transactionId)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });

        return promise;
    }

    doDeclineWithdrawal(transactionId){
        let promise = new Promise((resolve, reject) => {
            this.clientProfileFunction
                .doDeclineWithdrawal(transactionId)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });

        return promise;
    }

    approveWithdrawals(withdrawalId, coachName, coachId){
        let promise = new Promise((resolve, reject) => {
            this.clientProfileFunction
                .doApproveWithdrawals(withdrawalId, coachName, coachId)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });

        return promise;
    }

    TabChange = (event, newValue) => {
        this.setState({tabValue: newValue});
    }

    ItemChange1(event){
        this.setState({itemSelected: 1});
    }

    ItemChange2(event){
        this.setState({itemSelected: 2});
    }

    ItemTransactionChange = (event) => {
        this.setState({
            transSelectedId: event.currentTarget.dataset.transKey,
            transType: event.currentTarget.dataset.transType,
        })
    }

    NextPage = (event) => {
        var kycpage = this.state.kycPage;
        var page = kycpage === 1 ? (kycpage + 1) : (kycpage - 1);
        this.setState({kycPage: page});
    }

    onhandleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }

        this.setState({toggleAlert: false});
    };

    ShowImage = async (event) => {
        const imageType = Number(event.currentTarget.dataset.type);

        if (imageType === 3) {
            if (this.state.selfieSrc !== "") {
                this.setState({
                    imageType: imageType,
                    toggleShowImage: true,
                })
            } else {
                this.setState({
                    toggleAlert: true,
                    alertType: 'error',
                    alertMessage: 'Selfie with ID not uploaded yet.',
                });
            }
        }
        else if (imageType === 1) {
            if (this.state.idPhotoFrontSrc !== "") {
                this.setState({
                    imageType: imageType,
                    toggleShowImage: true,
                })
            } else {
                this.setState({
                    toggleAlert: true,
                    alertType: 'error',
                    alertMessage: 'ID not uploaded yet.',
                });
            }
        }
        else if (imageType === 2) {
            if (this.state.idPhotoBackSrc !== "") {
                this.setState({
                    imageType: imageType,
                    toggleShowImage: true,
                })
            } else {
                this.setState({
                    toggleAlert: true,
                    alertType: 'error',
                    alertMessage: 'ID not uploaded yet.',
                });
            }
        }

        else if (imageType === 4) {
            if (this.state.proofOfAddressSrc !== "") {
                this.setState({
                    imageType: imageType,
                    toggleShowImage: true,
                })
            } else {
                this.setState({
                    toggleAlert: true,
                    alertType: 'error',
                    alertMessage: 'Proof of Address not uploaded yet.',
                });
            }
        }

        else if (imageType === 5) {
            if (this.state.PEPDocumentSrc !== "") {
                this.setState({
                    imageType: imageType,
                    toggleShowImage: true,
                })
            } else {
                this.setState({
                    toggleAlert: true,
                    alertType: 'error',
                    alertMessage: 'PEP Document not uploaded yet.',
                });
            }
        }
        
        else{
            var details = this.state.investmentDetails.find(item => item.transId === this.state.transSelectedId);

            if(details.depositSlip !== "N/A"){
                await this.storage.child(details.depositSlip).getDownloadURL()
                .then((url) => {
                    this.setState({
                        depositSlipSrc: url,
                        imageType: imageType,
                        toggleShowImage: true,
                    });
                })
                .catch((error) => {
                    this.setState({
                        toggleAlert: true,
                        alertType: 'error',
                        alertMessage: error.message,
                    });
                })
            } else {
                this.setState({
                    toggleAlert: true,
                    alertType: 'error',
                    alertMessage: 'Deposit slip not uploaded yet.',
                });
            }
        }
    }

    CloseImage = (event) => {
        this.setState({
            toggleShowImage: false,
            imageType: -100,
        });
    }

    HandleCloseDialog(){
        this.setState({toggleDialog: false});
    }

    handleDialogDecline(){
        this.setState({
            toggleDialog: true,
            isApproveDecline: 'decline',
        });
    }

    handleDialogApprove(){
        this.setState({
            toggleDialog: true,
            isApproveDecline: 'approve',
        });
    }

    handleDialogType = (event) => {
        this.setState({
          toggleDialog: true,
          isApproveDecline: event.currentTarget.dataset.type,
        });
    }

    handleApproveTransaction = (event) => {
        this.setState({isLoading: true});

        const transactionId = this.state.transSelectedId;

        if(this.state.isApproveDecline === 'approve'){
            var coachName = this.state.clientDetails.investmentManager.investmentManagerName;
            const coachId = this.props.location.state.coach;
            if(this.state.transType.toLowerCase() === 'pending'){
                this.approveTransaction(transactionId, coachName, coachId)
                    .then((response) => {
                        this.setState({
                            toggleDialog: false,
                            isLoading: false,
                            toggleAlert: true,
                            alertType: 'success',
                            alertMessage: 'Transaction approved.',
                        });

                        this.getClientTransaction();
                    })
                    .catch((error) => {
                        this.setState({
                            toggleDialog: false,
                            isLoading: false,
                            toggleAlert: true,
                            alertType: 'error',
                            alertMessage: error.message,
                        });
                    });
            }
            else if(this.state.transType.toLowerCase() === 'pendingwithdrawal'){
                this.approveWithdrawals(transactionId, coachName, coachId)
                    .then((response) => {
                        this.setState({
                            toggleDialog: false,
                            isLoading: false,
                            toggleAlert: true,
                            alertType: 'success',
                            alertMessage: 'Withdrawal approved.',
                        });

                        this.getClientTransaction();
                    })
                    .catch((error) => {
                        this.setState({
                            toggleDialog: false,
                            isLoading: false,
                            toggleAlert: true,
                            alertType: 'error',
                            alertMessage: error.message,
                        });
                    });
            }
        } else {
            if(this.state.transType.toLowerCase() === 'pending'){
                this.declineTransaction(transactionId)
                .then((response) => {
                    this.setState({
                        toggleDialog: false,
                        isLoading: false,
                        toggleAlert: true,
                        alertType: 'success',
                        alertMessage: 'Transaction declined.',
                    });

                    this.getClientTransaction();
                })
                .catch((error) => {
                    this.setState({
                        toggleDialog: false,
                        isLoading: false,
                        toggleAlert: true,
                        alertType: 'error',
                        alertMessage: error.message,
                    });
                });
            }
            else if(this.state.transType.toLowerCase() === 'pendingwithdrawal'){
                this.doDeclineWithdrawal(transactionId)
                .then((response) => {
                    this.setState({
                        toggleDialog: false,
                        isLoading: false,
                        toggleAlert: true,
                        alertType: 'success',
                        alertMessage: 'Withdrawal Declined.',
                    });

                    this.getClientTransaction();
                })
                .catch((error) => {
                    this.setState({
                        toggleDialog: false,
                        isLoading: false,
                        toggleAlert: true,
                        alertType: 'error',
                        alertMessage: error.message,
                    });
                });
            }
        }
    }

    render(){
        return(
            <FirebaseContext.Consumer>
                {firebase =>
                    <ClientProfileFront
                        firebase={firebase}
                        state={this.state}
                        onTabChange={() => this.TabChange.bind(this)}
                        onItemChange1={() => this.ItemChange1.bind(this)}
                        onItemChange2={() => this.ItemChange2.bind(this)}
                        onNextPage={() => this.NextPage.bind(this)}
                        onhandleClose={() => this.onhandleClose.bind(this)}
                        onShowImage={() => this.ShowImage.bind(this)}
                        onCloseImage={() => this.CloseImage.bind(this)}
                        onItemTransactionChange={() => this.ItemTransactionChange.bind(this)}
                        onHandleCloseDialog={() => this.HandleCloseDialog.bind(this)}
                        onhandleDialogApprove={() => this.handleDialogApprove.bind(this)}
                        onhandleDialogDecline={() => this.handleDialogDecline.bind(this)}
                        onhandleApproveTransaction={() => this.handleApproveTransaction.bind(this)}
                        onhandleDialogType={() => this.handleDialogType.bind(this)}
                    />
                }
            </FirebaseContext.Consumer>
        );
    }
}

export default withRouter(ClientProfilePage);