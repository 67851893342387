import axios from 'axios';
import * as ROUTES from '../../constants/routes';
import Helpers from "../Helpers";

export default class ClientProfileFunction{
    constructor() {
        this.helpers = new Helpers();
    }

    doApproveTransaction = (investmentId, coachName, coachId) => {
        const token = this.helpers.getCookie("token");
        return new Promise((resolve, reject) => {
            axios.post(
                ROUTES.FUNCTIONAL_BASE_URL + '/investments-investments/processInvestment',
                JSON.stringify({
                    investmentId: investmentId,
                    approvedBy: {
                        approvedByManagerName: coachName,
                        approvedByManagerId: coachId,
                    },
                }),
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    }
                }
            )
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                this.helpers.handleError(err);
                reject(err);
            });
        });
    }
        

    doDeclineTransaction = (investmentId) => {
        const token = this.helpers.getCookie("token");
        return new Promise((resolve, reject) => {
            axios.post(
                ROUTES.FUNCTIONAL_BASE_URL + '/investments-investments/declineInvestment',
                JSON.stringify({
                    investmentId: investmentId,
                }),
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    }
                }
            )
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                this.helpers.handleError(err);
                reject(err);
            });
        });
    }
        

    doApproveWithdrawals = (withdrawalId, coachName, coachId) => {
        const token = this.helpers.getCookie("token");
        return new Promise((resolve, reject) => {
            axios.post(
                ROUTES.FUNCTIONAL_BASE_URL + '/investments-investments/processWithdrawInvestment',
                JSON.stringify({
                    transUid: withdrawalId,
                    approvedBy: {
                        approvedByManagerName: coachName,
                        approvedByManagerId: coachId,
                    },
                }),
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    }
                }
            )
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                this.helpers.handleError(err);
                reject(err);
            });
        });
    }
        

    doDeclineWithdrawal = (withdrawalId) => {
        const token = this.helpers.getCookie("token");
        return new Promise((resolve, reject) => {
            axios.post(
                ROUTES.FUNCTIONAL_BASE_URL + '/investments-investments/declineWithdrawInvestment',
                JSON.stringify({
                    transUid: withdrawalId,
                }),
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    }
                }
            )
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                this.helpers.handleError(err);
                reject(err);
            });
        });
    }
        
}