import React, { Component } from 'react'

import ClientProfileFront from './accountFront';

import { withRouter } from 'react-router-dom';

import { AuthUserContext } from '../Session';
import { FirebaseContext } from '../Firebase';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

import AccountFunc from './accountFunc';

const INITIAL_STATE = {
    currentUserId: '',
    currentUserEmail: '',
    currentUserNumber: '',
    currentUserName: '',
    selectedItem: 0,
    email: '',
    mobileNum: '',
    currentPass: '',
    newPass: '',
    confirmPass: '',
    emailError: '',
    mobileNumError: '',
    currentPassError: '',
    newPassError: '',
    confirmPassError: '',
    currentPassVisible: false,
    newPassVisible: false,
    confirmPassVisible: false,
    isLoading: false,
    toggleDialog: false,
    toggleAlert: false,
    alertType: 'warning',
    alertMessage: '',
  }

class ClientProfilePage extends Component {
    constructor(props) {
        super(props)
    
        this.state = {...INITIAL_STATE};
        this.db = firebase.firestore();
        this.storage = firebase.storage().ref();
        this.accountFunction = new AccountFunc();
        this.emailAuthProvider = firebase.auth.EmailAuthProvider;
    }

    static contextType = AuthUserContext;

    componentDidMount() {
        this.fetchDetails();
    }

    async fetchDetails(){
        await this.getCurrentUserDetails(this.context.uid)
        .then((response) => {
            this.setState({
                currentUserId: this.context.uid,
                currentUserName: response.name,
                currentUserEmail: response.email,
                currentUserNumber: response.mobilenum,
            });
        }).catch((error) => {
            this.setState({
                toggleAlert: true,
                alertType: 'error',
                alertMessage: error.message,
            })
        })
    }

    async getCurrentUserDetails(userId){
        var userDetails = [];

        await this.db.collection("users").where("__name__", "==", userId)
        .get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                if(doc.exists){
                    const data = doc.data();

                    var firstname = data.firstName || data.firstname !== '' ? data.firstName : 'N/A';
                    var lastName = data.lastName || data.lastName !== '' ? data.lastName : 'N/A';
                    var fullName = firstname + " " + lastName;

                    var email = data.email || data.email !== '' ? data.email : 'N/A'
                    var mobilenum = data.mobileNumber || data.mobileNumber !== '' ? data.mobileNumber : 'N/A'
                        
                    userDetails.push({
                        name: fullName,
                        email: email,
                        mobilenum: mobilenum,
                    })
                } else{
                    this.setState({
                        toggleAlert: true,
                        alertType: 'error',
                        alertMessage: "User details doesn't exist"
                    })
                }
            })
        }).catch((error) => {
             this.setState({
                toggleAlert: true,
                alertType: 'error',
                alertMessage: error.message
            })
        })

        return userDetails[0];
    }

    SetSelectedItem = (event) => {
        var selected = Number(event.currentTarget.dataset.item);
        this.setState({
            selectedItem: selected,
            email: '',
            emailError: '',
            mobileNum: '',
            mobileNumError: '',
            currentPass: '',
            currentPassError: '',
            currentPassVisible: false,
            newPass: '',
            newPassError: '',
            newPassVisible: false,
            confirmPass: '',
            confirmPassError: '',
            confirmPassVisible: false,
        });
    }

    HandleInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value }, () => {
            this.validator(event);
        });
    };

    MobileNumKeyUp = (event) => {
        const newVal = event.target.value.replace(/[^\d]/, "");
        this.setState(
          {
            mobileNum: newVal,
          },
          () => this.validator(event)
        );
    };

    validator = (e) => {
        const {
          email,
          mobileNum,
          currentPass,
          newPass,
          confirmPass,
        } = this.state;

        var strongRegex = new RegExp(
            "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,64}$)"
        );

        switch (e.target.name) {
            case "email":
                if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
                    this.setState({ emailError: "Please input a valid email address." });
                } else {
                    this.setState({ emailError: "" });
                }
                break; 
            case "mobileNum":
                if (mobileNum.length < 10) {
                    this.setState({
                        mobileNumError: "Please input correct mobile number",
                    });
                } else {
                    this.setState({
                        mobileNumError: "",
                    });
                }
                break;
            case "newPass":
                if (newPass.length <= 0) {
                    if (!strongRegex.test(newPass)) {
                      this.setState({
                        newPassError:
                          "Password should contain at least 1 special character, 1 numerical character, 1 uppercase character, 1 lowercase character. Maximum of 64 characters and minimum of 8 characters",
                      });
                    }
                } else if (newPass.length > 0 && newPass === currentPass) {
                    this.setState({
                        newPassError:
                          "New password should not the same with your current password",
                    });
                } else if (newPass !== currentPass){
                    if (!strongRegex.test(newPass)) {
                        this.setState({
                            newPassError:
                            "Password should contain at least 1 special character, 1 numerical character, 1 uppercase character, 1 lowercase character. Maximum of 64 characters and minimum of 8 characters",
                        });
                    } else {
                        this.setState({newPassError: ""});
                    }
                }
                break;
            case "confirmPass":
                    if (confirmPass.length <= 0) {
                        if (!strongRegex.test(confirmPass)) {
                          this.setState({
                            confirmPassError:
                              "Password should contain at least 1 special character, 1 numerical character, 1 uppercase character, 1 lowercase character. Maximum of 64 characters and minimum of 8 characters",
                          });
                        }
                    } else if (confirmPass.length > 0 && confirmPass !== newPass) {
                        this.setState({
                            confirmPassError:
                              "Password doesn't match",
                        });
                    } else if (confirmPass === newPass){
                        if (!strongRegex.test(confirmPass)) {
                            this.setState({
                                confirmPassError:
                                "Password should contain at least 1 special character, 1 numerical character, 1 uppercase character, 1 lowercase character. Maximum of 64 characters and minimum of 8 characters",
                            });
                        } else {
                            this.setState({confirmPassError: ""});
                        }
                    }
                    break;
            default:
                break;
        }
    };
    
    HandlePasswordVisibility = (event) => {
        this.setState({[event.currentTarget.dataset.name]: !this.state[event.currentTarget.dataset.name]})
    }

    HandleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
      
        this.setState({toggleAlert: false});
    }

    reAuthenticate = (currentPassword) => {
        var user = this.props.props.firebase.auth.currentUser;
        var cred = this.emailAuthProvider.credential(user.email, currentPassword);

        return user.reauthenticateWithCredential(cred);
    }

    HandleEditAccountSubmit = (event) => {
        event.preventDefault();
        this.setState({isLoading: true});

        const {
            email,
            mobileNum,
            emailError,
            mobileNumError
        } = this.state;

        const isValid =
            emailError === "" &&
            mobileNumError === "";

        if(isValid){
            this.accountFunction
                .doUpdateAccount(this.state.currentUserId, email, mobileNum)
                    .then((response) => {
                        this.setState({
                            isLoading: false,
                            toggleAlert: true,
                            alertType: 'success',
                            alertMessage: 'Account successfully updated.'
                        })
                        
                        this.fetchDetails();

                    }).catch((error) => {
                        this.setState({
                            isLoading: false,
                            toggleAlert: true,
                            alertType: 'error',
                            alertMessage: error.message
                        })
                    });
        } else {
            this.setState({
                isLoading: false,
                toggleAlert: true,
                alertType: 'error',
                alertMessage: 'Check all fields before proceeding'
            });
        }
    }

    HandleChangePassSubmit = (event) => {
        event.preventDefault();

        this.setState({isLoading: true});

        const {
            currentPass,
            newPass,
            currentPassError,
            newPassError,
            confirmPassError,
        } = this.state;

        const isValid =
            currentPassError === "" &&
            newPassError === "" &&
            confirmPassError === "";

        if(isValid){
            var user = this.props.props.firebase.auth.currentUser;

            this.reAuthenticate(currentPass)
                .then(() => {
                    user.updatePassword(newPass)
                        .then(() => {
                            this.setState({
                                isLoading: false,
                                toggleAlert: true,
                                alertType: 'success',
                                alertMessage: 'Password successfully changed.'
                            })

                            this.fetchDetails();
                        }).catch((err) => {
                            this.setState({
                                isLoading: false,
                                toggleAlert: true,
                                alertType: 'error',
                                alertMessage: err.message,
                            });
                        });
                }).catch((error) => {
                    this.setState({
                        isLoading: false,
                        toggleAlert: true,
                        alertType: 'error',
                        alertMessage: error.message,
                    });
                })

            
        } else {
            this.setState({
                isLoading: false,
                toggleAlert: true,
                alertType: 'error',
                alertMessage: 'Check all fields before proceeding'
            });
        }
    }

    render(){
        return(
            <FirebaseContext.Consumer>
                {firebase =>
                    <ClientProfileFront 
                        firebase={firebase}
                        state={this.state}
                        onSetSelectedItem={() => this.SetSelectedItem.bind(this)}
                        onHandleInputChange={() => this.HandleInputChange.bind(this)}
                        onMobileNumKeyUp={() => this.MobileNumKeyUp.bind(this)}
                        onHandlePasswordVisibility={() => this.HandlePasswordVisibility.bind(this)}
                        onHandleEditAccountSubmit={() => this.HandleEditAccountSubmit.bind(this)}
                        onHandleChangePassSubmit={() => this.HandleChangePassSubmit.bind(this)}
                        onHandleSnackbarClose={() => this.HandleSnackbarClose.bind(this)}
                    />
                }
            </FirebaseContext.Consumer>
        );
    }q
}

export default withRouter(ClientProfilePage);