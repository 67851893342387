import React, { Component } from 'react'

import Typography from '@material-ui/core/Typography';

import UserListFunction from './clientListFunction';
import { withFirebase } from '../Firebase';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import withAuthorization from '../Session/withAuthorization';

import NavigationMenus from '../NavigationMenus';

const UserListPage = () => {
  return(
    <>
      <NavigationMenus content={<UserListForm />} />
    </>
  );
};

class UserListPageBase extends Component {
  render(){
    return (
      <>
        <UserListFunction props={this.props} />
        <Typography 
          variant="caption"
          style={{display: 'block', marginBottom: 'auto', textAlign: 'center'}}
        >
          Copyright &copy; {(new Date().getFullYear())} RAMPVER. All rights reserved.
        </Typography>
      </>
    )
  }
} 

const condition = (authUser) => !!authUser;

const UserListForm = compose(
  withAuthorization(condition),
  withRouter,
  withFirebase
)(UserListPageBase);

export default UserListPage

export { UserListForm }
