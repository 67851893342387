import React, { Component } from 'react'
import { Alert } from '@material-ui/lab';

class AlertBox extends Component {
    render() {
        return (
            <Alert className="my-alert" severity={this.props.alert_type}>
                {this.props.alert_message}
            </Alert>
        )
    }
}

export default AlertBox
