import './App.css';
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import * as ROUTES from './constants/routes';
import SignIn from './components/SignIn';
import Home from './components/Home';
import { createMuiTheme,ThemeProvider } from '@material-ui/core/styles';
import { withAuthentication } from './components/Session';
import ClientList from './components/ClientList';
import ClientProfile from './components/ClientProfile';
import TransactionList from './components/Operations/TransactionList.js';
import Account from './components/Account';
import InvalidToken from "./components/GenericPages/InvalidToken";
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#C8932A'
    },
  },
});

const App = () => (
  <Router>
    <ThemeProvider theme={theme}>
      <Switch>
        <Route exact path={ROUTES.SIGN_IN} component={SignIn} />
        <Route exact path={ROUTES.LANDING} component={ClientList} />
        <Route exact path={ROUTES.CLIENT_PORFILE} component={ClientProfile} />
        <Route exact path={ROUTES.TRANSACTION_LIST} component={TransactionList} />
        <Route exact path={ROUTES.ACCOUNT} component={Account} />
        <Route path={ROUTES.HOME} component={Home} />    
        <Route exact path={ROUTES.INVALID} component={InvalidToken} />    
      </Switch>
    </ThemeProvider>
  </Router>
);
 
export default withAuthentication(App);
