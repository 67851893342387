import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import { withFirebase } from "../Firebase";
import  { withAuthorization } from "../Session";
import { compose } from 'recompose';
 
class InvalidToken extends Component {
    constructor(props){
        super(props);
        setTimeout(() => {
            this.props.firebase.doSignOut();
          }, 3000);
    }
    render() {
        return (
        <Container>
            <div id="message" className="r-title-container text-center">
                <p className="r-title">The Token has Expired or Invalid.</p>
                <p className="r-subtitle">
                    You will be signout, please wait a moment...
                </p>
            </div>
        </Container>
        );
    }
}

const condition = (authUser) => !!authUser;

export default compose(withAuthorization(condition), withFirebase)(InvalidToken);
